import { gql, useLazyQuery } from '@apollo/client';
import { Avatar, AvatarGroup, Backdrop, Badge, Box, Button, Card, Fade, Grid, IconButton, LinearProgress, ListItem, ListItemAvatar, ListItemText, Menu, MenuItem, Paper, Typography, linearProgressClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { BiDotsVerticalRounded, BiLogoMongodb, BiLogoPostgresql, BiSolidDollarCircle } from 'react-icons/bi';
import { FaCode, FaDatabase, FaServer } from 'react-icons/fa';
import { MdAdd, MdArrowDropDown, MdOutlineArrowForwardIos, MdOutlineOpenInNew } from 'react-icons/md';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import UpgradePlan from '../../../Containers/UpgradePlan';
import { USER_ACTIVITY_TIME_BAESD } from '../../Analytics';
import { MEMBERS_LIST } from '../../Org/MembersTable';
import Nav from '../../Settings/Nav';
import { CustomTooltip, ORG_LIST } from '../../Settings/Organization';
import CreateNewApp from '../CreateNewApp/Main';
import New from '../CreateNewApp/New';
import { OVERVIEW_DATA } from '../Dashboard';
import DeploymentTable from '../DeploymentTable';
import NifeLineChart from '../Graphs/LineChart';
import LocationGraph from '../LocationGraph';
import OrgGraph from '../OrgGraph';
import { SITES_LIST } from '../Sites/SitesTable';
import WorkloadBarChart from '../WorkloadBarChart';
import UpgradeToUnlock from './UpgradeToUnlock';

import analyticsImg from '../../../Assets/images/analytics.png';
import codtMonitorImg from '../../../Assets/images/costmonitor.png';
import databaseImg from '../../../Assets/images/database.png';
import inviteUserImg from '../../../Assets/images/inviteUser.png';
import marketplaceImg from '../../../Assets/images/marketplace.png';
import siteImg from '../../../Assets/images/site.png';

import Joyride, { STATUS } from 'react-joyride';
import { SiMariadb, SiMysql, SiRedis } from 'react-icons/si';
import { GrMysql } from 'react-icons/gr';
import ExpiredPlanAlert from '../../../Tools/ExpiredPlanAlert';
import { CONFIG_APP_BASE_COMPANY } from '../../../configApp';







export const BYOC_LOCATION_LIST = gql`
query{
    getUserAddedRegions{
        id
        clusterType
        providerType
        regionName
        externalCloudType
       
    }
}`

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

function CardWrapper(props) {
    return (
        <>
            <Card variant='outlined' sx={{
                minHeight: "158px",
                boxShadow: "rgba(17, 12, 46, 0.04) 0px 20px 30px 0px", p: 2,
                borderTopRightRadius: "12px", borderTopLeftRadius: "12px",
                borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px",
            }}>
                {props.children}

            </Card>
            <StyledLinearProgressBar
                variant="determinate"
                value={props.value}
                sx={{
                    width: '100%',
                    height: 5,  // Adjust the height as needed
                    boxShadow: "rgba(17, 12, 46, 0.04) 0px 20px 30px 0px",
                    borderBottomRightRadius: "12px", borderBottomLeftRadius: "12px",
                }}
            />
        </>


    )
}

const StyledLinearProgressBar = styled(LinearProgress)({
    [`&.${linearProgressClasses.determinate}`]: { backgroundColor: "#8ba1ec30" },
    [`&.${linearProgressClasses.determinate} > .${linearProgressClasses.bar1Determinate}`]: { background: CONFIG_APP_BASE_COMPANY === "nife" ? `linear-gradient(to right, #4dfce0, #a977f1)` : `linear-gradient(to right, #C59C9A, #D1A64D)
` }
});

export default function DashboardHome() {



    let history = useHistory();


    // Joyride state
    const [run, setRun] = useState(false);
    const [steps, setSteps] = useState([
        {
            content: <div>
                <b style={{ color: "#5d86f9", fontSize: "14px", fontFamily: "Rubik" }}>WELCOME TO OIKOS</b>
                <h2 style={{ marginBottom: "2px", fontFamily: "Rubik" }}>Let's begin our journey!🎉</h2>
                <span style={{ marginBottom: "20px", fontSize: "13px", color: "grey", fontFamily: "Rubik" }}>Click on Next for a quick tour of <b>Oikos dashboard</b></span>
            </div>,
            locale: { skip: <strong aria-label="skip">SKIP</strong> },
            placement: 'center',
            target: 'body',
        },
        {
            content: (
                <div>
                    <p>Welcome! This is your launchpad.</p>
                    <p>Once you deploy an application, your dashboard will look like this:</p>
                    <img src="https://catpajamas-site-3443.static.nifetency.com/static/media/image%20(1).6f32a088aeec7e7a9285.png" alt="Dashboard example" style={{ width: '100%', height: 'auto' }} />
                </div>
            ),
            placement: 'right',
            target: '.demo_dashboard',
        },

        {
            content: (
                <div>
                    <h4>Organization</h4>
                    <p>This is where you create and manage groups of applications under different Organization.</p>
                    <img src="https://docs.nife.io/assets/images/org1-491b25c91e3b322868cee1453778f242.png" alt="Organization" style={{ width: '100%', height: 'auto' }} />
                </div>
            ),
            placement: 'right',
            target: '.demo__orgs_tab',
        },

        {
            content: (
                <div>
                    <h4>Workload</h4>
                    <p>This is your hub for managing different environments like development, staging, and production.</p>
                    <img src="https://docs.nife.io/assets/images/work1-554f5ea7e9db1aad05c5f57329bef52d.png" alt="workload" style={{ width: '100%', height: 'auto' }} />
                </div>
            ),
            placement: 'right',
            target: '.demo__workload_tab',
        },

        {
            content: (
                <div>
                    <h4>Application</h4>
                    <p>This is your main spot for creating, monitoring, and updating your apps.</p>
                    <img src="https://docs.nife.io/assets/images/app15-833230ba5223feb56dbca2695f67d170.png" alt="Application section" style={{ width: '100%', height: 'auto' }} />


                </div>
            ),
            placement: 'right',
            target: '.demo__apps_tab',
        },
        {
            content: (
                <div>
                    <h4>Site Deployment</h4>
                    <p>You can simply upload your build file, upload your source code from local storage, or import it from GitHub. We'll then generate an optimized production build to deploy your website.</p>
                    <img src={siteImg} alt="Site Deployment" style={{ width: '100%', height: 'auto' }} />


                </div>
            ),
            placement: 'right',
            target: '.demo__site',
        },
        {
            content: (
                <div>
                    <h4>Database</h4>
                    <p>You can deploy MySQL and PostgreSQL databases, ensuring everything runs smoothly and securely.</p>
                    <img src={databaseImg} alt="Database" style={{ width: '100%', height: 'auto' }} />
                </div>
            ),
            placement: 'right',
            target: '.demo__database',
        },
        {
            content: (
                <div>
                    <h4>Location</h4>
                    <p>Choose where to deploy your applications, integrating clusters from AWS, Azure, GCP, or a standalone cluster.</p>
                    <img src="https://docs.nife.io/assets/images/active-f70b30fbe3c619d317e7cc93b89aa89c.png" alt="Nife Location" style={{ width: '100%', height: 'auto' }} />
                </div>
            ),
            placement: 'right',
            target: '.demo_location',
        },
        {
            content: (
                <div>
                    <h4>Usere Analytics</h4>
                    <p>Track your activity history from app deployments , organization , workload and location.</p>
                    <img src={analyticsImg} alt="Analytics" style={{ width: '100%', height: 'auto' }} />
                </div>
            ),
            placement: 'right',
            target: '.demo_analytics',
        },
        {
            content: (
                <div>
                    <h4>Cost Monitoring(Platus)</h4>
                    <p> Connect your AWS or GCP account for detailed analysis and reports on your cloud expenses.</p>
                    <img src={codtMonitorImg} alt="Platus Cost monitoring" style={{ width: '100%', height: 'auto' }} />
                </div>
            ),
            placement: 'right',
            target: '.demo_cost_monitoring',
        },
        {
            content: (
                <div>
                    <h4>Marketplace Application</h4>
                    <p>Deploy popular apps quickly and easily.</p>
                    <img src={marketplaceImg} alt="Nife markerplace" style={{ width: '100%', height: 'auto' }} />
                </div>
            ),
            placement: 'right',
            target: '.demo_market_place',
        },


        {
            content: (
                <div>
                    <h4>Invite Members</h4>
                    <p>Keep your team organized and connected by adding and managing members.</p>
                    <img src={inviteUserImg} alt="incite Member" style={{ width: '100%', height: 'auto' }} />

                </div>
            ),
            placement: 'right',
            target: '.demo_member',
        },
        //   {
        //     content: <div>
        //         <h2 style={{ marginBottom: "2px" }}>Do you like to deploy your first application on Oikos?</h2>
        //     </div>,
        //     locale: { skip: <strong aria-label="skip">SKIP</strong> },
        //     placement: 'center',
        //     target: 'body',
        // },
        {
            content: (
                <div>
                    <h3>Start deploying your application by clicking here.</h3>
                </div>
            ),
            placement: 'top',
            target: '.demo__create_app',
        },

        {
            content: (
                <div>
                    <h3>Learn everything you need from our detailed documentation.</h3>
                </div>
            ),
            placement: 'right',
            target: '.demo_learn_more',
        },


    ]);

    const handleJoyrideCallback = (data) => {
        const { status, type } = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            setRun(false);
            localStorage.removeItem("showUserGuide");
        }
    };



    const handleCheckCostmonetory = async (email) => {
        setcostMonitoringLoading(true)
        try {
            const response = await fetch(
                "https://backend.clb2.nifetency.com/api/set-config",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ emailid: email }),
                }
            );

            if (!response.ok) {
                throw new Error("Failed to send email. Please try again.");
            }
            const data = await response.json();
            // Set the dashboard active state based on the 'exists' property in the response.
            if (data.exists) {
                setcostMonitoring(true); // Only set active if 'exists' is true.
            } else {
                setcostMonitoring(false); // Optionally, handle the 'false' case.
            }

            
        } catch (err) {

        } finally {
            setcostMonitoringLoading(false)
        }
    }
   

    const [fetchMembers] = useLazyQuery(MEMBERS_LIST, {
        onCompleted: (data) => {
            if (data && data.getUserByAdmin) {
                const useremail = data.getUserByAdmin.find((element) => element.id === localStorage.getItem("userId"));
                handleCheckCostmonetory(useremail.email)
                setodv(prevState => ({
                    ...prevState,
                    memberCount: data.getUserByAdmin.length,
                    memberJson: data.getUserByAdmin,
                }));
            }
        }
    })
    const [fetchAppOverview, { loading }] = useLazyQuery(OVERVIEW_DATA, {
        onCompleted: (data) => {
            if (data && data.AppsCount) {
                setodv(prevState => ({
                    ...prevState,
                    appsCount: data.AppsCount.totalApps - (odv.dbCount),
                    activeAppCount: data.AppsCount.active - (odv.dbCount),
                    newAppCount: data.AppsCount.new,
                    suspendedAppCount: data.AppsCount.inActive,
                    showNewUser: (costMonitoring ? false : data.AppsCount.totalApps === 0 ? true : false),
                }));
            }
        }
    });
    const [fetchOrg] = useLazyQuery(ORG_LIST, {
        variables: { userId: localStorage.getItem("userId") },
        onCompleted: (data) => {
            if (data && data.organizations.nodes) {
                var temp = data.organizations.nodes.find((e) => {
                    return e.type === "true" || e.type === "1"
                })
                setodv(prevState => ({
                    ...prevState,
                    organizationCount: data.organizations.nodes.filter((e) => {
                        return e.parentId === ""
                    }).length,
                    defaultOrganization: temp ? temp.name : "no default organization",
                }));
            }
        }
    });

    const [fetchBYOC] = useLazyQuery(BYOC_LOCATION_LIST, {
        onCompleted: (data) => {
            if (data && data.getUserAddedRegions) {
                if (data.getUserAddedRegions[0].providerType === "aws") {
                    setodv(prevState => ({
                        ...prevState,
                        byocAws: 1
                    }));
                }

                if (data.getUserAddedRegions[0].providerType === "gcp") {
                    setodv(prevState => ({
                        ...prevState,
                        byocGcp: 1
                    }));
                }

                if (data.getUserAddedRegions[0].providerType === "azure") {
                    setodv(prevState => ({
                        ...prevState,
                        byocAzure: 1
                    }));
                }
            }
        }
    });



    function generateDataForPast24Hours(activities, chartType) {
        const currentDate = new Date();
        const data = [];

        for (let i = 23; i >= 0; i--) {
            const hour = (currentDate.getHours() - i + 24) % 24;
            const hourStr = hour < 10 ? `0${hour}` : `${hour}`;
            const time = `${hourStr}:00`;

            if (chartType === "app") {
                const appCreated = activities.filter((activity) => {
                    return (moment(activity.createdAt).format('HH') == hour && activity.type === 'APP' && activity.activities === 'CREATED')
                }).length;

                const appDeployed = activities.filter((activity) => {
                    return (moment(activity.createdAt).format('HH') == hour && activity.type === 'APP' && activity.activities === 'DEPLOYED')
                }).length;

                data.push({
                    time,
                    appCreated,
                    appDeployed
                });
            }

            if (chartType === "site") {
                const siteDeployed = activities.filter((activity) => {
                    return (moment(activity.createdAt).format('HH') == hour && activity.type === 'SITE' && activity.activities === 'DEPLOYED')
                }).length;

                data.push({
                    time,
                    siteDeployed
                });
            }


            // data.push({
            //     time,
            //     siteCreated,
            //     siteDeployed
            // });
        }

        return data;
    }


    const [fetchUserActivity, { data: useractivitydata, loading: userActivityLoad }] = useLazyQuery(USER_ACTIVITY_TIME_BAESD, {
        variables: { startDate: moment().subtract(1, 'days').format('YY-MM-DD'), endDate: moment().add(1, 'days').format('YY-MM-DD') },
        onCompleted: (data) => {
            if (data && data.userActivitiesByDate) {
                // setodv(prevState => ({
                //     ...prevState,
                //     appsChartActivity: [],
                // }));
                var timeSeries = generateDataForPast24Hours(data.userActivitiesByDate, "app");
                setodv(prevState => ({
                    ...prevState,
                    appsChartActivity: timeSeries,
                }));
            }
        }
    });

    const [fetchSites] = useLazyQuery(SITES_LIST, {
        onCompleted: (data) => {
            if (data.getAllS3deployments) {
                setodv(prevState => ({
                    ...prevState,
                    sitesCount: data.getAllS3deployments.length,
                    sitesActive: data.getAllS3deployments.length,
                }));
            }

        }
    });


    const [odv, setodv] = useState({
        organizationCount: 0,
        defaultOrganization: "",
        appsCount: 0,
        sitesCount: 0,
        sitesActive: 0,
        appsChartActivity: [],
        dbCount: 0,
        wlCount: 0,
        wlItem: [],
        locationCount: 0,
        locationPresentCount: 1,
        memberCount: 0,
        memberJson: [],
        activeAppCount: 0,
        newAppCount: 0,
        suspendedAppCount: 0,
        byocAws: 0,
        byocGcp: 0,
        byocAzure: 0,
        dbMysql: 0,
        dbPostgres: 0,
        dbMaria: 0,
        dbMongo: 0,
        dbRedis: 0,
        showNewUser: false,
    });
    const [costMonitoring, setcostMonitoring] = useState(false);
    const [costMonitoringLoading, setcostMonitoringLoading] = useState(false);
    const [access, setaccess] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedChart, setSelectedChart] = useState('Apps Chart');
    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };
    const handleMenuItemClick = (chart) => {
        setSelectedChart(chart);
        handleCloseMenu();

        var timeSeries = generateDataForPast24Hours(useractivitydata.userActivitiesByDate, chart === 'Apps Chart' ? "app" : "site");
        setodv(prevState => ({
            ...prevState,
            appsChartActivity: timeSeries,
        }));
    };


    const calculatePercentage = (value, total) => {
        if (value && total) {
            return (value / total) * 100
        }
        else {
            return 0
        }
    }



    useEffect(() => {
        if (localStorage.getItem("deployLink")) {
            history.push(`/deploy?name=${localStorage.getItem("deployLink")}`);
        }



        if (odv.showNewUser && localStorage.getItem("showUserGuide") && !localStorage.getItem("deployLink")) {
            setRun(true)
        }

        fetchOrg();
        fetchMembers();
        fetchAppOverview();
        fetchUserActivity();
        fetchBYOC();
        fetchSites();

        if (localStorage.getItem("access")) {
            setaccess(JSON.parse(localStorage.getItem("access")))
        }
        return () => {

        };
    }, [userActivityLoad,costMonitoring]);





    if (access === null) {
        return "You don't have access."
    }
 
    if ( localStorage.getItem("expiredaccount")) {
        return (<>
            <Nav />
            <ExpiredPlanAlert />
        </>)
    }
   
    return (
        <div>
   
          <main style={{  flexGrow: 1,
        height: '99vh',
        overflow: 'auto',}}>
            <Nav />
            <Joyride
                callback={handleJoyrideCallback}
                continuous
                hideCloseButton
                run={run}
                scrollToFirstStep
                showProgress
                showSkipButton
                steps={steps}
                styles={{
                    options: {
                        fontFamily: "Rubik",
                        primaryColor: "#160044",
                        zIndex: 10000,
                    },
                }}
            />
            <Fade in={odv.showNewUser} mountOnEnter unmountOnExit style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 300 } : {})}>
                <div style={{ height: "90vh", margin: "0px 20px" }}>
                    <New />
                </div>
            </Fade>

            <Fade in={!odv.showNewUser} style={{ transformOrigin: '0 0 0' }} {...((!odv.showNewUser) ? { timeout: 0 } : {})} mountOnEnter unmountOnExit>
                <div>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ px: 3 }}
                    >
                        <Typography variant='body2' sx={{ fontWeight: "lighter", mb: 1 }} color={"primary"}>Here what's happening in your teamboard account today,  <a target='_blank' style={{ color: "grey" }} href={"https://nife-demo-videos.s3.ap-south-1.amazonaws.com/Nife+Platform+%EF%BD%9C+A+Comprehensive+Overview+%23NifePlatform+%23AppManagement+%23Deployment+%23developerlife.mp4"}>
                            View more about Overview
                        </a></Typography>
                        <Box>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"

                            >
                                <CreateNewApp totalapps={odv.appsCount} />
                                {/* <IconButton sx={{
                                    fontSize: "1.2rem", border: "1px solid #8080803d", background: "#fff", ml: 1,
                                    boxShadow: "rgba(58, 59, 69, 0.15) 0px 0.15rem 1.75rem 0px"
                                }}><IoIosArrowDown /></IconButton> */}
                            </Grid>
                        </Box>

                        {/* <Button startIcon={<MdAdd />} onClick={()=> history.push("/new")} variant='contained' sx={{ borderRadius: "24px" }} color='primary'>New Project</Button> */}
                    </Grid>
                    <Box sx={{ mx: 3, mt: 2 }}>
                        <Grid container spacing={2}>
                            <Fade in={true} timeout={500 * 1}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <CardWrapper value={calculatePercentage(odv.organizationCount, access.organizationCount)}>
                                        <Box>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                            >

                                                <Typography sx={{ fontWeight: "bold" }}>
                                                    Organizations
                                                </Typography>
                                                <BiDotsVerticalRounded />
                                            </Grid>
                                            <CustomTooltip arrow placement="bottom-start" title={<Box sx={{ p: 1 }}>
                                                <b style={{ textTransform: "capitalize" }}>Total Organizations : {odv.organizationCount}</b>
                                                <hr />
                                                <Typography sx={{ my: 1, textTransform: "capitalize" }} variant='body2'>
                                                    Default organization ☑️ :<b>{odv.defaultOrganization.toLowerCase()} </b>
                                                </Typography>
                                                <Typography sx={{ my: 1 }} variant='body2'>
                                                    Members : <b>{odv.memberCount}</b>
                                                </Typography>


                                            </Box>}>

                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="flex-end"
                                                    onClick={() => history.push("/organizations")}
                                                >
                                                    <Typography variant="h3" sx={{ fontWeight: "lighter" }}>
                                                        {odv.organizationCount}
                                                    </Typography>
                                                    <Typography sx={{ fontWeight: "lighter", mb: 1 }}>
                                                        /{access.organizationCount}
                                                    </Typography>
                                                    {/* <Chip label="Added 1 today" size="small" color="success" icon={<GrLineChart style={{ fontSize: "12px", padding: "1px" }} />} sx={{ fontSize: 10, height: 20, borderRadius: 8, ml: 2, background: "rgb(229, 252, 235)", color: "green" }} /> */}
                                                </Grid>


                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    sx={{ py: 1 }}
                                                >

                                                    <AvatarGroup max={4}>
                                                        {odv.memberJson.length > 0 && odv.memberJson.slice(0, 4).map((e, i) => {
                                                            return <Avatar key={e.email} sx={{ width: 24, height: 24 }} alt={e.firstName} src={e.profileImageUrl} />
                                                        })}


                                                    </AvatarGroup>
                                                    <Typography variant='body2'>{odv.memberCount} Members</Typography>
                                                </Grid>
                                            </CustomTooltip>

                                        </Box>
                                    </CardWrapper>

                                </Grid>
                            </Fade>
                            <Fade in={true} timeout={500 * 2}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <CardWrapper value={calculatePercentage(odv.appsCount + odv.dbCount, access.apps)}>
                                        <Box onClick={() => history.push("/apps")}>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"

                                            >

                                                <Typography sx={{ fontWeight: "bold" }}>
                                                    Apps
                                                </Typography>
                                                <BiDotsVerticalRounded />
                                            </Grid>
                                            <CustomTooltip arrow placement="bottom-start" title={<Box sx={{ p: 1 }}>
                                                <b style={{ textTransform: "capitalize" }}>Total apps : {odv.appsCount}</b>
                                                <hr />
                                                <Typography sx={{ my: 1 }} variant='body2'>
                                                    Active apps ✅ : <b>{odv.activeAppCount}</b>
                                                </Typography>
                                                <Typography sx={{ my: 1 }} variant='body2'>
                                                    New apps ☑️ :<b>{odv.newAppCount} </b>
                                                </Typography>
                                                <Typography sx={{ my: 1 }} variant='body2'>
                                                    Suspended apps ❌ :<b> {odv.suspendedAppCount}</b>
                                                </Typography>
                                            </Box>}>

                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="flex-end"
                                                >

                                                    <Typography variant="h3" sx={{ fontWeight: "lighter" }}>
                                                        {odv.appsCount}
                                                    </Typography>
                                                    <Typography sx={{ fontWeight: "lighter", mb: 1 }}>
                                                        /{access && access.apps}
                                                    </Typography>
                                                </Grid>

                                                <Grid container>
                                                    <Grid xs="12">
                                                        <ListItem sx={{ px: 1 }}>
                                                            <StyledBadge
                                                                overlap="circular"
                                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                                variant="dot"
                                                            >
                                                                <FaServer />
                                                            </StyledBadge>
                                                            <ListItemText
                                                                sx={{
                                                                    ml: 2,
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    whiteSpace: 'nowrap',
                                                                    maxWidth: 'calc(100% - 48px)',// Set width to allow ellipsis to work
                                                                }}
                                                                primaryTypographyProps={{ variant: 'body2', sx: { fontSize: 14 } }}
                                                                primary={<>{odv.activeAppCount !== 0 && `${odv.activeAppCount} Active`} {odv.newAppCount !== 0 && `• ${odv.newAppCount} New`}  {odv.suspendedAppCount !== 0 && `• ${odv.suspendedAppCount} Suspended`} </>} />

                                                        </ListItem>
                                                    </Grid>

                                                </Grid>
                                            </CustomTooltip>

                                        </Box>
                                    </CardWrapper>

                                </Grid>
                            </Fade>
                            <Fade in={true} timeout={500 * 3}>
                                <Grid item xs={12} sm={6}>

                                    <Paper variant='outlined' sx={{ minHeight: "158px", boxShadow: "rgba(17, 12, 46, 0.04) 0px 20px 30px 0px", pt: 2, px: 2, borderRadius: "12px" }}>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        // onClick={() => history.push("/apps")}
                                        >
                                            <Typography sx={{ fontWeight: "bold", mx: 0, mb: 1 }}>{selectedChart}<IconButton onClick={handleClickMenu} sx={{ p: 0 }}><MdArrowDropDown /></IconButton></Typography>
                                            <Typography sx={{ fontSize: "10px", color: "grey", mb: 1 }}>Last 24 hours</Typography>
                                        </Grid>
                                        <Menu
                                            id="simple-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={handleCloseMenu}
                                        >
                                            <MenuItem
                                                selected={selectedChart === 'Apps Chart'}
                                                onClick={() => handleMenuItemClick('Apps Chart')}
                                            >
                                                App Chart
                                            </MenuItem>
                                            <MenuItem
                                                selected={selectedChart === 'Sites Chart'}
                                                onClick={() => handleMenuItemClick('Sites Chart')}
                                            >
                                                Site Chart
                                            </MenuItem>
                                        </Menu>

                                        {odv.appsChartActivity.length > 0 ?
                                            <Box>
                                                <Fade in={true} timeout={500}>
                                                    <Box>
                                                        <NifeLineChart type={selectedChart === 'Apps Chart' ? "app" : "site"} data={odv.appsChartActivity} />
                                                    </Box>
                                                </Fade>
                                            </Box>

                                            :
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{ minHeight: "100px" }}
                                            >
                                                Fetching data...
                                            </Grid>}

                                    </Paper>
                                </Grid>
                            </Fade>
                            <Fade in={true} timeout={500 * 4}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <CardWrapper value={calculatePercentage(odv.appsCount | odv.dbCount, access.apps)}>
                                        <Box onClick={() => history.push("/databases")}>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"

                                            >

                                                <Typography sx={{ fontWeight: "bold" }}>
                                                    Databases
                                                </Typography>
                                                <BiDotsVerticalRounded />
                                            </Grid>
                                            <CustomTooltip arrow placement="bottom-start" title={<Box sx={{ p: 1 }}>
                                                <b style={{ textTransform: "capitalize" }}>Total Databases : {odv.dbCount}</b>
                                                <hr />
                                                <Typography sx={{ my: 1 }} variant='body2'>
                                                    MySQL database: <b>{odv.dbMysql !== 0 && "✅"} {odv.dbMysql} Active</b>
                                                </Typography>
                                                <Typography sx={{ my: 1 }} variant='body2'>
                                                    PostgreSQL :<b>{odv.dbPostgres !== 0 && "✅"} {odv.dbPostgres} Active</b>
                                                </Typography>
                                                <Typography sx={{ my: 1 }} variant='body2'>
                                                    MariaDB :<b>{odv.dbMaria !== 0 && "✅"} {odv.dbMaria} Active</b>
                                                </Typography>
                                                <Typography sx={{ my: 1 }} variant='body2'>
                                                    MongoDB :<b>{odv.dbMongo !== 0 && "✅"} {odv.dbMongo} Active</b>
                                                </Typography>
                                                <Typography sx={{ my: 1 }} variant='body2'>
                                                    Redis :<b>{odv.dbRedis !== 0 && "✅"} {odv.dbRedis} Active</b>
                                                </Typography>
                                            </Box>}>

                                                {access.planName !== "free plan" ? <Typography variant="h3" sx={{ fontWeight: "lighter" }}>
                                                    {odv.dbCount}
                                                </Typography> : <UpgradeToUnlock />}

                                                <Box sx={{ mt: 1.5 }}>


                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                    >

                                                        <Grid xs={2.4}>
                                                            <Badge
                                                                color="default"
                                                                sx={{
                                                                    '& .MuiBadge-dot': {
                                                                        backgroundColor: '#f5f5f5', // Set badge background color
                                                                    },
                                                                    '& .MuiBadge-badge': {
                                                                        backgroundColor: '#f5f5f5', // Set badge background color
                                                                        color: 'black',          // Set text color inside badge
                                                                    }
                                                                }}
                                                                overlap="circular"
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                badgeContent={odv.dbMysql}
                                                            >
                                                                <GrMysql style={{

                                                                    color: "#005e86", width: '30px',
                                                                    height: 'auto',       // Adjust height based on width while maintaining aspect ratio
                                                                    maxWidth: '100%',
                                                                }} />
                                                            </Badge>
                                                        </Grid>
                                                        <Grid xs={2.4}>
                                                            <Badge
                                                                color="default"
                                                                sx={{
                                                                    '& .MuiBadge-dot': {
                                                                        backgroundColor: '#f5f5f5', // Set badge background color
                                                                    },
                                                                    '& .MuiBadge-badge': {
                                                                        backgroundColor: '#f5f5f5', // Set badge background color
                                                                        color: 'black',          // Set text color inside badge
                                                                    }
                                                                }}
                                                                overlap="circular"
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                badgeContent={odv.dbPostgres}
                                                            >

                                                                <BiLogoPostgresql style={{
                                                                    color: "#31648c", width: '30px',
                                                                    height: 'auto',       // Adjust height based on width while maintaining aspect ratio
                                                                    maxWidth: '100%',
                                                                }} />

                                                            </Badge>
                                                        </Grid>
                                                        <Grid xs={2.4}>
                                                            <Badge
                                                                color="default"
                                                                sx={{
                                                                    '& .MuiBadge-dot': {
                                                                        backgroundColor: '#f5f5f5', // Set badge background color
                                                                    },
                                                                    '& .MuiBadge-badge': {
                                                                        backgroundColor: '#f5f5f5', // Set badge background color
                                                                        color: 'black',          // Set text color inside badge
                                                                    }
                                                                }}
                                                                overlap="circular"
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                badgeContent={odv.dbMaria}
                                                            >
                                                                <SiMariadb style={{
                                                                    color: "#c0765a", width: '30px',
                                                                    height: 'auto',       // Adjust height based on width while maintaining aspect ratio
                                                                    maxWidth: '100%',
                                                                }} />
                                                            </Badge>
                                                        </Grid>
                                                        <Grid xs={2.4}>
                                                            <Badge
                                                                color="default"
                                                                sx={{
                                                                    '& .MuiBadge-dot': {
                                                                        backgroundColor: '#f5f5f5', // Set badge background color
                                                                    },
                                                                    '& .MuiBadge-badge': {
                                                                        backgroundColor: '#f5f5f5', // Set badge background color
                                                                        color: 'black',          // Set text color inside badge
                                                                    }
                                                                }}
                                                                overlap="circular"
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                badgeContent={odv.dbMongo}
                                                            >
                                                                <BiLogoMongodb style={{
                                                                    color: "green", width: '30px',
                                                                    height: 'auto',       // Adjust height based on width while maintaining aspect ratio
                                                                    maxWidth: '100%',
                                                                }} />
                                                            </Badge>
                                                        </Grid>
                                                        <Grid xs={2.4}>
                                                            <Badge
                                                                color="default"
                                                                sx={{
                                                                    '& .MuiBadge-dot': {
                                                                        backgroundColor: '#f5f5f5', // Set badge background color
                                                                    },
                                                                    '& .MuiBadge-badge': {
                                                                        backgroundColor: '#f5f5f5', // Set badge background color
                                                                        color: 'black',          // Set text color inside badge
                                                                    }
                                                                }}
                                                                overlap="circular"
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                badgeContent={odv.dbRedis}
                                                            >
                                                                <SiRedis style={{
                                                                    color: "#d12b1f", width: '30px',
                                                                    height: 'auto',       // Adjust height based on width while maintaining aspect ratio
                                                                    maxWidth: '100%',
                                                                }} />
                                                            </Badge>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </CustomTooltip>

                                        </Box>
                                    </CardWrapper>
                                </Grid>
                            </Fade>
                            <Fade in={true} timeout={500 * 5}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <CardWrapper value={calculatePercentage(odv.sitesCount, access.apps)}>
                                        <Box onClick={() => history.push("/sites")}>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"

                                            >

                                                <Typography sx={{ fontWeight: "bold" }}>
                                                    Sites
                                                </Typography>
                                                <BiDotsVerticalRounded />
                                            </Grid>
                                            <CustomTooltip arrow placement="bottom-start" title={<Box sx={{ p: 1 }}>
                                                <b style={{ textTransform: "capitalize" }}>Total sites : {odv.sitesCount}</b>
                                                <hr />
                                                <Typography sx={{ my: 1 }} variant='body2'>
                                                    Active sites ✅ : <b>{odv.sitesCount}</b>
                                                </Typography>
                                                {/* <Typography sx={{ my: 1 }} variant='body2'>
                                                    New apps ☑️ :<b>{odv.newAppCount} </b>
                                                </Typography>
                                                <Typography sx={{ my: 1 }} variant='body2'>
                                                    Suspended apps ❌ :<b> {odv.suspendedAppCount}</b>
                                                </Typography> */}
                                            </Box>}>

                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="flex-end"
                                                >

                                                    <Typography variant="h3" sx={{ fontWeight: "lighter" }}>
                                                        {odv.sitesCount}
                                                    </Typography>
                                                    {/* <Typography sx={{ fontWeight: "lighter", mb: 1 }}>
                                                        /{access && access.apps}
                                                    </Typography> */}
                                                </Grid>

                                                <Grid container>
                                                    <Grid xs="12">
                                                        <ListItem sx={{ px: 1 }}>
                                                            <StyledBadge
                                                                overlap="circular"
                                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                                variant="dot"
                                                            >
                                                                <FaCode />
                                                            </StyledBadge>
                                                            <ListItemText
                                                                sx={{
                                                                    ml: 2,
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    whiteSpace: 'nowrap',
                                                                    maxWidth: 'calc(100% - 48px)',// Set width to allow ellipsis to work
                                                                }}
                                                                primaryTypographyProps={{ variant: 'body2', sx: { fontSize: 14 } }}
                                                                primary={<>{odv.sitesActive !== 0 ? `${odv.sitesActive} Active` : "No sites deployed"} </>} />

                                                        </ListItem>
                                                    </Grid>

                                                </Grid>
                                            </CustomTooltip>

                                        </Box>
                                    </CardWrapper>

                                </Grid>
                            </Fade>
                            <Fade in={true} timeout={500 * 6}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <CardWrapper value={access.planName !== "free plan" ? 100 : 0}>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            onClick={() => history.push("/workloads")}
                                        >

                                            <Typography sx={{ fontWeight: "bold" }}>
                                                Workloads
                                            </Typography>
                                            <BiDotsVerticalRounded />
                                        </Grid>
                                        <CustomTooltip arrow placement="bottom-start" title={<Box sx={{ p: 1 }}>
                                            <b style={{ textTransform: "capitalize" }}>Total Workloads : {odv.wlCount}</b>
                                            <hr />
                                            {odv.wlItem.length > 0 && odv.wlItem.map((e) => {
                                                return (<Typography sx={{ my: 1 }} variant='body2'>
                                                    {e.environmentName}: <b> {e.apps.nodes ? e.apps.nodes.length : 0} Apps</b>
                                                </Typography>)
                                            })}

                                        </Box>}>


                                            {access.workloadManagement ?
                                                <Typography variant="h3" sx={{ fontWeight: "lighter" }}> {odv.wlCount}   </Typography>

                                                : <UpgradeToUnlock />}


                                            {(access.workloadManagement && odv.wlItem && odv.wlItem.length > 0) ? <Grid
                                                container
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                            >
                                                <Typography variant='body2' sx={{ pt: 1 }}>{odv.wlItem[0].environmentName} • {odv.wlItem[0].apps.nodes ? odv.wlItem[0].apps.nodes.length : 0} apps • {calculatePercentage(odv.wlItem[0].apps.nodes ? odv.wlItem[0].apps.nodes.length : 0, odv.appsCount).toFixed(0)}%</Typography>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={calculatePercentage(odv.wlItem[0].apps.nodes ? odv.wlItem[0].apps.nodes.length : 0, odv.appsCount)}
                                                    sx={{
                                                        width: '100%',
                                                        height: 5,  // Adjust the height as needed
                                                        borderRadius: 10,  // Adjust the border radius for rounded corners
                                                        marginBottom: 1,
                                                    }}
                                                />

                                            </Grid> : <Button sx={{ mt: 1 }} size='small' onClick={() => history.push("workloads?create=new")} variant='outlined' startIcon={<MdAdd />} fullWidth>production workload</Button>}
                                        </CustomTooltip>

                                    </CardWrapper>
                                </Grid>
                            </Fade>
                            <Fade in={true} timeout={500 * 7}>

                                <Grid item xs={12} sm={6} md={3}>
                                    <CardWrapper value={calculatePercentage(odv.locationCount, odv.locationPresentCount)}>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            onClick={() => history.push("/location")}
                                        >

                                            <Typography sx={{ fontWeight: "bold" }}>
                                                Locations
                                            </Typography>
                                            <BiDotsVerticalRounded />
                                        </Grid>



                                        <CustomTooltip arrow placement="bottom-start" title={<Box sx={{ p: 1 }}>
                                            <b style={{ textTransform: "capitalize" }}>{odv.locationCount} Locations using, {odv.locationCount} present</b>
                                            <hr />
                                            <Typography sx={{ my: 1 }} variant='body2'>
                                                BYOC location
                                                <ul>
                                                    {odv.byocAws !== 0 && <li>{odv.byocAws} AWS location</li>}
                                                    {odv.byocGcp !== 0 && <li>{odv.byocGcp} GCP location</li>}
                                                    {odv.byocAzure !== 0 && <li>{odv.byocAzure} Azure location</li>}
                                                </ul>

                                            </Typography>
                                            <Typography sx={{ my: 1 }} variant='body2'>
                                                500+ nife locations available to add
                                            </Typography>

                                        </Box>}>



                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="flex-end"
                                            >

                                                <Typography variant="h3" sx={{ fontWeight: "lighter" }}>
                                                    {odv.locationCount}
                                                </Typography>
                                                <Typography sx={{ fontWeight: "lighter", mb: 1 }}>
                                                    /{odv.locationPresentCount}
                                                </Typography>
                                            </Grid>


                                            <Grid
                                                sx={{ py: 1 }}
                                                container
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                            >
                                                <Typography>BYOC
                                                </Typography>
                                                <>
                                                    <Avatar variant='rounded' sx={{ width: "auto", height: 24, p: "3px" }} src="https://upload.wikimedia.org/wikipedia/commons/9/93/Amazon_Web_Services_Logo.svg" />{odv.byocAws}
                                                    <Avatar variant='rounded' sx={{ width: 24, height: 24 }} src="https://brandeps.com/logo-download/G/Google-Cloud-logo-vector-01.svg" />{odv.byocGcp}
                                                    <Avatar variant='rounded' sx={{ width: 24, height: 24 }} src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Microsoft_Azure.svg" />{odv.byocAzure}
                                                </>
                                            </Grid>

                                        </CustomTooltip>


                                    </CardWrapper>
                                </Grid>
                            </Fade>
                            {/* <Fade in={true} timeout={500 * 6}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <CardWrapper value={costMonitoring ? 100 : 0}>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"

                                        >

                                            <Typography sx={{ fontWeight: "bold" }}>
                                                Cloud Billing
                                            </Typography>
                                            <BiDotsVerticalRounded />
                                        </Grid>
                                        <CustomTooltip arrow placement="bottom-start" title={<Box sx={{ p: 1 }}>
                                            <b style={{ textTransform: "capitalize" }}>{costMonitoring && "Active"}</b>
                                            <hr />

                                            <Typography sx={{ my: 1 }} variant='body2'>
                                                Click to open cost Monitoring dashboard
                                            </Typography>

                                        </Box>}>

                                            <Typography variant="h3" sx={{ fontWeight: "lighter" }}>
                                                {costMonitoring ? "Active" : "Inactive"}
                                            </Typography>

                                            {costMonitoring ? <Button
                                                onClick={() => {
                                                    window.open(`${costMonitoring.hostname}?token=${localStorage.getItem("token") && localStorage.getItem("token")}`, '_blank')
                                                }}
                                                size='small' sx={{ py: 1 }} endIcon={<MdOutlineOpenInNew />} fullWidth>Open platus</Button> :
                                                <Button onClick={() => history.push("/Cost-Monitoring")} variant='outlined' size='small' sx={{ my: 1 }} fullWidth>Enable now</Button>
                                            }
                                        </CustomTooltip>


                                    </CardWrapper>
                                </Grid>
                            </Fade> */}

                        </Grid>

                        <Box sx={{ my: 0,pt:2 }}>


                            <Grid container spacing={2}>
                            
                                <Grid item xs={12} sm={8}>
                                    <CustomTooltip arrow placement="bottom" title={<Box sx={{ p: 1 }}>
                                        <b style={{ textTransform: "capitalize" }}>{costMonitoring && "Active"}</b>
                                        <hr />

                                        <Typography sx={{ my: 1 }} variant='body2'>
                                            Click to open Cost Monitoring dashboard
                                        </Typography>

                                    </Box>}>
                                        <ListItem sx={{
                                            m: 0, p: 0,
                                            borderRadius: "12px",
                                            boxShadow: "none",
                                            border: "0px solid transparent",
                                            background: 'transparent',
                                            transition: 'all 0.7s ease-in-out',
                                            '&:hover': {
                                                padding: 2,

                                                boxShadow: "rgba(17, 12, 46, 0.04) 0px 20px 30px 0px",
                                                background: 'linear-gradient(100deg, rgb(77, 252, 224, 0.6) 0%, rgb(168, 120, 241, 0.6) 100%)',
                                            },
                                        }}

                                            onClick={() => {
                                                costMonitoring ?
                                                    window.open(`https://platus.clb2.nifetency.com?token=${localStorage.getItem("token") && localStorage.getItem("token")}`, '_blank')
                                                    :
                                                    history.push("/Cost-Monitoring")
                                            }}
                                            secondaryAction={
                                                <>
                                                    {costMonitoring ? <Button
                                                        onClick={() => {
                                                            window.open(`https://platus.clb2.nifetency.com?token=${localStorage.getItem("token") && localStorage.getItem("token")}`, '_blank')
                                                        }}
                                                        size='small' sx={{ py: 1 }} endIcon={<MdOutlineOpenInNew />} >Open platus</Button> :
                                                        <Button onClick={() => history.push("/Cost-Monitoring")} size='small' sx={{ my: 1 }} endIcon={<MdOutlineArrowForwardIos />} >Enable now</Button>
                                                    }
                                                </>
                                            }>
                                            <ListItemAvatar>
                                                <IconButton color='primary' sx={{ borderRadius: "12px", bgcolor: (theme) => theme.palette.action.hover }}>
                                                    {costMonitoring ? <StyledBadge
                                                        overlap="circular"
                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                        variant="dot"
                                                    >
                                                        <BiSolidDollarCircle style={{ fontSize: "30px" }} />
                                                    </StyledBadge> :
                                                        <BiSolidDollarCircle style={{ fontSize: "30px" }} />}
                                                </IconButton>

                                           
                                            </ListItemAvatar>
                                            <ListItemText primary={<Typography sx={{ fontWeight: "bold", textAlign: "left" }} gutterBottom>{costMonitoringLoading ? "Fetching data..." : "Cloud Billing"}</Typography>}
                                                secondary={costMonitoringLoading ? "Please wait we are gathering data for your dashboard" : (costMonitoring ? "Active" : "Activate Platus to manage cloud billing effectively")}
                                            />
                                        </ListItem>
                                    </CustomTooltip>
                                    <Box sx={{ py: 2 }}>
                                        <Card variant='outlined' sx={{ p: 2, borderRadius: "12px", boxShadow: "rgba(17, 12, 46, 0.04) 0px 20px 30px 0px" }}>
                                            <DeploymentTable setodv={setodv} />
                                        </Card>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4}>


                                    {<Box sx={{ m: 2 }}>
                                        <Typography style={{ fontWeight: "bold", textAlign: "left" }} gutterBottom>Location Usage</Typography>
                                        {access.applicationHealthDashboard ?

                                            <LocationGraph setodv={setodv} />
                                            : <div><UpgradePlan center={true} /></div>}
                                    </Box>}

                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Box sx={{ py: 2 }}>
                                        <OrgGraph />

                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <WorkloadBarChart setodv={setodv} />
                                </Grid>
                            </Grid>
                        </Box>

                    </Box>
                </div>
            </Fade>
        </main>
        </div>
    )
}
