import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import React, { useState } from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from 'react-router-dom';
import { closeLoginAgain, login, openLoginAgain, restMsg } from '../actions/index';
import GitHubLoginButton from "./GitHubLoginButton";
import GitlabLoginButton from "./GitlabLoginButton";
import GoogleLoginButton from "./GoogleLoginButton";


import { Collapse, Paper } from '@mui/material';
import { MdOutlineArrowBack } from 'react-icons/md';
import BibucketLoginButton from "./BibucketLoginButton";
import { CONFIG_APP_BASE_COMPANY } from '../configApp';


var CryptoJS = require("crypto-js");

function Copyright() {
  return (
    <Typography variant="body2" sx={{ fontSize: "12px", py: 2 }} color="textSecondary" align="center">


      {'© '}

      {new Date().getFullYear()}{' '}
      <a href={"https://nife.io"} target="_blank" rel="noopener noreferrer" style={{ color: "#5d86f9", marginTop: "10px", textDecoration: "none" }}>
        nife.io
      </a>
      {' • '}
      <Link to="/policies/privacy-policy/" style={{ color: "#5d86f9", marginTop: "10px", textDecoration: "none" }}>
        Privacy policy
      </Link>

      {' • '}{'v' + process.env.REACT_APP_VERSION}

    </Typography>
  );
}

export const StyledButton = withStyles({
  root: {


  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const useStyles = makeStyles((theme) => ({
  image: {
    backgroundImage: 'url(https://res.cloudinary.com/dfxupfsej/image/upload/v1624509812/login_bnolqb.jpg)',
    backgroundColor: '#3a53a5',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: "50%",
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '90%',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    borderRadius: "50%",
  },
  grid: {
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    background: '#e12717',
    marginBottom: "20px"
  },
  hr: {
    margin: "10px",
    color: "#ffe",
    height: 0,
  },
  error: {
    paddingLeft: "10px",
    color: "red",
    margin: "0px"
  },
}));

const useStylesCustomizeTextField = makeStyles((theme) => ({
  root: {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 24,
    height: '48px',
    marginTop: '5px',
    marginBottom: '5px',
    backgroundColor: theme.palette.background.paper,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    },
    '&$focused': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
  focused: {},
}));

function CustomizeTextField(props) {
  const classes = useStylesCustomizeTextField();

  return <TextField InputProps={{ classes, disableUnderline: true }} {...props} />;
}

function Login(props) {
  const isDarkTheme = useTheme().palette.mode === 'dark';
  const [emailValidation, setEmailValidation] = useState(false)
  const valuevalidateEmail = (e) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const checkvalue = re.test(e.target.value.toLowerCase())
    if (checkvalue.length > 0) {
      setEmailValidation(false)
    }
    setEmailValidation((checkvalue) ? false : true)
  }

  const [dynamicText, setDynamicText] = useState("Anything!");
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [checked, setChecked] = useState(false)
  const [progress, setProgress] = useState(false)
  const [open, setOpen] = useState(true);

  const [isLoginWithEmail, setisLoginWithEmail] = useState(false);

  const classes = useStyles();
  const { isAuthenticated } = props.authReducer
  const { msg } = props.authReducer
  const { loginAgain } = props.authReducer


  if (isAuthenticated) {
    return <Redirect to='/teamboard' />
  }



  const handleSubmit = (e) => {
    e.preventDefault();
    setProgress(true)
    var one = encodeAes(password)
    console.log("one=>", one)
    const data = {
      "data": {
        "attributes": {
          "email": `${email}`,
          "password": `${one}`
        }
      }
    }
    props.login(data, checked, postlogin)
  }

  const handleClose = () => {
    props.closeLoginAgain(false)
  }

  const handleChange = () => {
    setChecked(!checked)
  }

  const postlogin = () => {
    setProgress(false)
  }


  function encodeAes(plaintText) {
    var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_KEY);
    var encryptedData = CryptoJS.AES.encrypt(plaintText, key, {
      iv: key,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    encryptedData = encryptedData.ciphertext.toString();
    return encryptedData

  }



  return (
    <Paper elevation={0}>

      <Grid container component="main" className={classes.root}>


        <CssBaseline />
        <Grid item xs={false} sm={2} md={3} lg={3} display={{ xs: "none", lg: "block" }}  >
          <Box    >

            <Grid
              container
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              sx={{ bgcolor: "#0e0042", border: "0px solid red", minHeight: "99vh" }}
            >
              <Box></Box>
              <img alt="Nife Banner" src={"https://react-nife-2024.s3.ap-south-1.amazonaws.com/banner.gif"} style={{ objectFit: "cover", objectPosition: "end" }} height="auto" width="100%" />

            </Grid>
          </Box>
        </Grid>

        <Grid container direction={"column"} justifyContent={"center"} alignItems={"flex-start"} item xs={12} md={4} sx={{ height: "99hv", pl: 8, pr: 4 }}>
          {isDarkTheme ?
            <img alt="Oikos" loading="lazy" src={"/OikosDark.svg"} height="120px" width="140px" /> :
            <img alt="Oikos" loading="lazy" src={CONFIG_APP_BASE_COMPANY === "nife" ? "/Logo.svg" : "/Zeke.svg"} height="120px" width="140px" />}
          <Typography variant='h3' sx={{ fontWeight: "bold", fontFamily: "Rubik", fontSize: "2rem" }}>
            Deploy {dynamicText} Anytime Anywhere!
          </Typography>

          <Typography sx={{ mt: 2 }} variant='body2'>
            Deploy workloads to distributed computing resources over multiple infrastructures, environments & geographies.</Typography>



          {/* <Button>Submit</Button> */}
        </Grid>
        <Grid container direction={"column"} justifyContent={"space-between"} alignItems={"center"} item xs={12} md={5} sx={{ height: "99hv", py: 2, borderLeft: "0px solid grey" }}>
          <Box sx={{ mt: 2 }}>



          </Box>
          <form className={classes.form} onSubmit={e => handleSubmit(e)}>
            {msg && <div style={{ padding: "10px", borderRadius: "5px", background: "#fdf6f6", marginBottom: "10px",margin:"0px 64px" }}>
              <Typography variant="subtitle1" align="center" display="block" color="error" gutterBottom>
                {msg}
              </Typography>
            </div>}
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={loginAgain} autoHideDuration={30000} onClose={handleClose}>
              <Alert elevation={6} variant="filled" onClose={handleClose} severity="success">
                Successfully registered, please login!
              </Alert>
            </Snackbar>
            <Box sx={{ px: 8, borderLeft: "1px solid #8080802e" }}>
              <Typography variant='h6' sx={{ fontWeight: "bold", py: 2 }}>Log In</Typography>

              <Typography variant='bdoy2' sx={{ color: "grey", mb: 4 }}>New here?
                <Button component={Link} to="/register" size='medium'
                  sx={{
                    fontSize: "0.975rem", py: 0, textTransform: "none", color: "grey", mb: 0,
                    '&:hover': {
                      color: theme => theme.palette.primary.main,  // Change text color to blue on hover
                      textDecoration: "underline", // Optional: Add underline on hover
                    },
                  }}  >
                  <u>Create an account</u>
                </Button>
              </Typography>
              <Box sx={{ mt: 1 }}>
                <CustomizeTextField
                  label="Email Address"
                  className={classes.margin}
                  fullWidth={email !== ""}
                  error={emailValidation && email !== ""}
                  variant="filled"
                  id="text-input"
                  required
                  helperText={emailValidation && email !== "" && "Invalid email"}
                  type={'email'}
                  value={email}
                  size={"small"}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    valuevalidateEmail(e);
                    props.restMsg()
                  }}
                  autoFocus
                />

                <Collapse in={email !== ""}>
                  <Box>
                    <CustomizeTextField
                      label="Password"
                      className={classes.margin}
                      fullWidth
                      variant="filled"
                      size={"small"}
                      id="password-input"
                      required
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        props.restMsg();
                      }}
                      type={'password'}
                    />
                    <FormControlLabel
                      control={<Checkbox checked={checked} onChange={handleChange} color="primary" />}
                      label="Remember me"
                    />



                    <Button sx={{
                      borderRadius: 25,
                      border: 0,
                      height: 48,
                      padding: '0 30px',
                      margin: '1px',
                      marginBottom: '5px',

                    }}
                      variant='contained'
                      disableElevation
                      color='primary'
                      disabled={emailValidation} type="submit" fullWidth>{progress ? <CircularProgress size={20} style={{ color: "white" }} /> : "Sign In"}</Button>

                    <Button component={Link} to="/forgetpassword" size='medium' sx={{ textTransform: "inherit" }} color='primary' >
                      Forgot password
                    </Button>


                    <Button size='small' fullWidth variant='text' color='info' disableElevation onClick={() => setEmail("")} sx={{ mt: 2, py: 0 }} startIcon={<MdOutlineArrowBack style={{ fontSize: "30px" }} />}>Other Login options</Button>

                  </Box>
                </Collapse>
              </Box>
              <Collapse in={email === ""}>
                <Box>
                  <Typography sx={{ py: 2 }}>Or Continue with following options</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <GoogleLoginButton />

                    </Grid>
                    <Grid item xs={6}>
                      <GitHubLoginButton />

                    </Grid>
                    <Grid item xs={6}>
                      <GitlabLoginButton />

                    </Grid>
                    <Grid item xs={6}>
                      <BibucketLoginButton />
                    </Grid>
                  </Grid>
                </Box>
              </Collapse>
            </Box>
          </form>
          <Box>
            <Copyright />
          </Box>
        </Grid>
        {/* <Grid component={Box} item xs={12} sm={12} md={5} lg={5} display={{ xs: "none", lg: "block" }}>


          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            sx={{ height: "100vh", pl: 6 }}
          >

            <Typography variant='h3' sx={{ mt: 3, mb: 2, fontWeight: "lighter", fontFamily: "Rubik" }}>
              Deploy {dynamicText} Anytime Anywhere!
            </Typography>

            <Typography sx={{ mt: 2 }} variant='body2'>
              Deploy workloads to distributed computing resources over multiple infrastructures, environments & geographies.</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} className={classes.grid}>

          <div className={classes.paper}>
        

      

            <form className={classes.form} onSubmit={e => handleSubmit(e)}>
              {msg && <div style={{ padding: "10px", borderRadius: "5px", background: "#fdf6f6", marginBottom: "10px" }}>
                <Typography variant="subtitle1" align="center" display="block" color="error" gutterBottom>
                  {msg}
                </Typography>
              </div>}
              <Snackbar
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                open={loginAgain} autoHideDuration={3000} onClose={handleClose}>
                <Alert elevation={6} variant="filled" onClose={handleClose} severity="success">
                  Successfully registered, please login!
                </Alert>
              </Snackbar>

              {isLoginWithEmail ?
                <Box>


                  <CustomizeTextField
                    label="Email Address"
                    className={classes.margin}
                    fullWidth
                    error={emailValidation}
                    variant="filled"
                    id="text-input"
                    required
                    helperText={emailValidation && "Invalid email"}
                    type={'email'}
                    value={email}
                    size={"small"}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      valuevalidateEmail(e);
                      props.restMsg()
                    }}
                    autoFocus
                  />

                  <CustomizeTextField
                    label="Password"
                    className={classes.margin}
                    fullWidth
                    variant="filled"
                    size={"small"}
                    id="password-input"
                    required
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      props.restMsg();
                    }}
                    type={'password'}
                  />
                  <FormControlLabel
                    control={<Checkbox checked={checked} onChange={handleChange} color="primary" />}
                    label="Remember me"
                  />



                  <Button sx={{
                    borderRadius: 25,
                    border: 0,
                    height: 48,
                    padding: '0 30px',
                    margin: '1px',
                    marginBottom: '5px',

                  }}
                    variant='contained'
                    disableElevation
                    color='primary'
                    disabled={emailValidation} type="submit" fullWidth>{progress ? <CircularProgress size={20} style={{ color: "white" }} /> : "Sign In"}</Button>

                  <Button size='small' fullWidth variant='text' color='info' disableElevation onClick={() => setisLoginWithEmail(false)} sx={{ borderRadius: "24px", mt: 2, py: 0 }} startIcon={<MdOutlineArrowBack style={{ fontSize: "30px" }} />}>Other Login options</Button>


                </Box> :

                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={12}>
                    <Button size='large' fullWidth variant='outlined' disableElevation onClick={() => setisLoginWithEmail(true)} sx={{ borderRadius: "24px", mt: 2, textTransform: "capitalize", border: "1px solid rgba(0, 0, 0, 0.12)", py: 1 }} startIcon={<MdOutlineMail />}>Sign In with Email</Button>
                    <Divider style={{ marginTop: "10px", marginBottom: "10px" }} > OR </Divider>
                  </Grid>
                  <Grid item xs={12}>
                    <GoogleLoginButton />

                  </Grid>
                  <Grid item xs={12}>
                    <GitHubLoginButton />

                  </Grid>
                 <Grid item xs={12}>
                    <GitlabLoginButton />

                  </Grid>
                  <Grid item xs={12}>
                    <BibucketLoginButton />
                  </Grid> 



                </Grid>}



              <Grid wrap="wrap" container direction="column" sx={{ my: 2 }}>


                <Button component={Link} to="/register" size='medium' sx={{ textTransform: "inherit" }} color='primary' >
                  Don't have an account? Sign Up
                </Button>

                <Button component={Link} to="/forgetpassword" size='medium' sx={{ textTransform: "inherit" }} color='primary' >
                  Forgot password
                </Button>
              </Grid>





              <Box mt={0}>
                <Copyright />
              </Box>
            </form>

          </div>
        </Grid> */}

      </Grid>




    </Paper>
  );
}

const mapStateToProps = ({ authReducer }) => {

  return { authReducer }
}
export default connect(mapStateToProps, { login, openLoginAgain, closeLoginAgain, restMsg })(Login)