import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Grid, Fade, Divider } from '@mui/material';
import { RiArrowGoBackFill } from 'react-icons/ri';
import UpgradePlan from '../../../../Containers/UpgradePlan';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { SITE_OVERVIEW } from './Overview';
import { SITES_LIST } from '../SitesTable';

export const REVERT_OVERVIEW = gql`
query revertOverviewQuery($s3AppName: String){
  getS3deploymentsList(s3AppName: $s3AppName) {
        id
        s3AppName
        status
        appId
        appUrl
        version
      }
}`

export const REVERT_S3_DEPLOYMENT = gql`
  mutation RevertS3Deployment($id: String!, $s3AppName: String!, $VersionID: String!) {
    RevertS3Deployment(input: { id: $id, s3AppName: $s3AppName, VersionID: $VersionID })
  }
`;

export default function RevertSite({s3AppName,id,isAction}) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false); // Manage dialog open/close state
  const [editLoading, setEditLoading] = useState(false); // Manage loading state
  const [history, sethistory] = useState([]);

  const [revertS3Deployment] = useMutation(REVERT_S3_DEPLOYMENT,{
    refetchQueries: [SITE_OVERVIEW,SITES_LIST,REVERT_OVERVIEW],
    onError:(err)=>{
      setEditLoading(false)
      enqueueSnackbar(err.message, {
        variant: 'error',
        anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
        },
    })
    }
    ,
    onCompleted:()=>{
      handleClose()
      setEditLoading(false)
      enqueueSnackbar('Successfully reverted', {
        variant: 'success',
        anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
        },
    })
    }
  });

  const [fetchRevertDetails] = useLazyQuery(REVERT_OVERVIEW, {
    onCompleted:(data)=>{
      if(data){
        console.log("revert",data)
        sethistory(data.getS3deploymentsList);
      }
    }
  });

  const handleClose = () => setOpen(false);
  const handleClickRevert = (e) => {
    // Define your revert logic here
    setEditLoading(true)
    revertS3Deployment({
      variables: {
        id: id,
        s3AppName: s3AppName ? s3AppName :"",
        VersionID: e.version,
      },
    });
  };


  useEffect(() => {
    if(isAction){
      setOpen(!open)
    }
    fetchRevertDetails({variables:{
      s3AppName: s3AppName ? s3AppName :"",
    }})
  }, [])
  
  if (history.length === 0 ){
    return "No Releases"
  }
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {editLoading && <LinearProgress />}
        <DialogTitle id="alert-dialog-title">
          {editLoading ? <div>Please wait...</div> : 'Revert back to previous version'}
        </DialogTitle>
        <DialogContent>
          <TableContainer>
            {localStorage.getItem('access') && JSON.parse(localStorage.getItem('access')).versionControlPanel ? (
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow style={{ fontWeight: 'bold' }}>
                    {/* <TableCell align="left">Image Name</TableCell> */}
                    <TableCell align="center">Version</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {history.length && history.map((e, i) => (
                    <Fade
                      key={e.id}
                      in={true}
                      style={{ transformOrigin: '0 0 0' }}
                      {...{ timeout: 200 * i }}
                    >
                      <TableRow>
                        {/* <TableCell align="left">{e.image}</TableCell> */}
                        <TableCell align="center">{e.version}</TableCell>
                        <TableCell align="center">{e.status === "Active" ? 
                          <div style={{ color: "green", background: "rgb(229, 252, 235)", borderRadius: "24px", padding: "0px 10px 0px 10px", border: "1px green " }} > {"Active"}</div>:
                          "Inactive"}</TableCell>
                        <TableCell align="right">
                          {e.status === 'Active' ? (
                            'Current Version'
                          ) : (
                            <Button disabled={editLoading} onClick={() => handleClickRevert(e)}>
                              Activate
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    </Fade>
                  ))}
                </TableBody>
              </Table>
            ) : (
               <UpgradePlan /> 
            )}
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button disabled={editLoading} onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {!isAction && <>
      <Divider sx={{mt:2}}/>
      <h3>Revert</h3>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Typography>
          Click on the following button to revert to the previous version of this site.
        </Typography>
        <Button variant="contained" endIcon={<RiArrowGoBackFill />} onClick={() => setOpen(!open)}>
          Revert
        </Button>
      </Grid>
      </>}
   
    </div>
  );
}
