import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useTheme } from '@mui/material/styles';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import CheckCircle from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import FilterListIcon from '@mui/icons-material/FilterList';
import { AlertTitle, Avatar, Box, Paper } from '@mui/material';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import { useSnackbar } from 'notistack';
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker
} from "react-simple-maps";
import { ReactComponent as MySvgFile } from '../../../Images/world.svg';
import LogTrigger from '../../../LogTrigger';
import { OVERVIEW_DATA } from '../Dashboard';
import { APPS } from '../DeploymentTable';
import { LOCATIONS_GRAPH_DATA } from '../LocationGraph';
import { ORG_GRAPH_DATA } from '../OrgGraph';
import { InfoRounded } from '@mui/icons-material';
import { APP_LOCATION_LIST } from '../../Deployments/Deploy/Main';
import { CustomSwitch } from './StepTwo';
import { AiFillGithub } from 'react-icons/ai';
import { Octokit } from '@octokit/rest';
import { IoIosArrowForward, IoIosInfinite, IoMdArrowRoundBack } from 'react-icons/io';
import { CgBorderStyleDotted } from 'react-icons/cg';
import { FaInfinity } from 'react-icons/fa';
import { MdCode, MdFileCopy } from 'react-icons/md';
import ConfigDownload from '../../Deployments/Services/ConfigDownload';
import { APP } from '../../Deployments/Overview/Main';
import PingChecker from '../../../Utils/pingChecker';
import { CustomTooltip } from '../../Settings/Organization';
import { CONFIG_APP_BASE_COMPANY } from '../../../configApp';


const DEPLOY = gql`
  mutation ConfigReg($allowRegions: [String!], $appId: String!, $backupRegions: [String]){
    configureRegions(input: {allowRegions:$allowRegions, appId:$appId, backupRegions : $backupRegions }){
      id
      name
      status
      deployed
      hostname
      appUrl
      config{
        definition
      }
      version
      imageName
      port
      regions{
        name
      }
    }
  }
`

export const TOKEN_1000 = gql`
mutation {
  setUserTokenExpireTime(ExpireTime: 1000)
}`

const REGIONS_OPTIONS = gql`
query regionsOptions($isLatency: String!){
  getAvailabilityCluster(isLatency: $isLatency, first: 13){
    nodes {
      id
      name
      regionCode
      ipAddress
      clusterConfigPath
      clustertype
      isLatency
      latitude
      longitude
    }
  }
}`

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    height: theme.spacing(16),
  },
  paper: {
    backgroundColor: "whitesmoke",
    padding: theme.spacing(2),
    width: "100%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  btncolor: {
    background: theme.palette.background.paper,
    color: theme.palette.text.secondary,
  },
  button: {
    position: 'relative',
    overflow: 'hidden',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: -1,
      border: '2px solid transparent',
      borderRadius: '4px',
      animation: '$pulse 3s infinite',
    },
  },
  '@keyframes pulse': {
    '0%': { borderColor: theme.palette.primary.main },
    '25%': { borderColor: theme.palette.secondary.main },
    '50%': { borderColor: theme.palette.error.main },
    '75%': { borderColor: theme.palette.warning.main },
    '100%': { borderColor: theme.palette.primary.main },
  },
}));


export default function Main(props) {
  const role = localStorage.getItem("role")
  const githubCICDtoken = JSON.parse(localStorage.getItem("githubCICDtoken"))

  const octokit = new Octokit({
    auth: `token ${githubCICDtoken ? githubCICDtoken.token : ""}` //  Replace with your personal access token
  });



  const [isRedirect, setIsRedirect] = React.useState(false)
  const [isDeployed, setIsDeployed] = React.useState(false)
  const [regionsList, setRegionsList] = React.useState([])
  const [showFilter, setshowFilter] = useState(false)
  const [openAlert, setopenAlert] = useState(true);
  const [showGithubCICD, setshowGithubCICD] = useState(false);
  const [latencyValue, setlatencyValue] = useState(false)
  const [backupValue, setbackupValue] = useState(false)
  const [code, setCode] = useState(`
  name: Deploy to Nife
  on: [push]
  jobs:
    deploy:
      name: Deploy proxy
      runs-on: ubuntu-latest
      steps:
        # This step checks out a copy of your repository.
        - uses: actions/checkout@v2
        # This step runs nifectl deploy.
        - uses: nifetency/nife-actions@2.4
          env:
            NIFE_ACCESS_TOKEN: \${{ secrets.NIFE_ACCESS_TOKEN }}
          with:
            args: "deploy --remote-only"`);
  const { enqueueSnackbar } = useSnackbar();

  const geoUrl = "/features.json";
  const isDarkTheme = useTheme().palette.mode === 'dark';
  const [markers, setmarkers] = useState([]);
  const [deployedMarkers, setdeployedMarkers] = useState([]);
  const [backmarkers, setbackmarkers] = useState([]);
  const [githubCICDerror, setgithubCICDerror] = useState("");
  const [repoUrl, setrepoUrl] = useState("");

  const { data: datarepo, error: apperr } = useQuery(APP, {
    variables: {
      name: props.name
    },
    onCompleted: (data) => {
      if (data) {
        setrepoUrl((data.app.sourceUrl && data.app.sourceUrl.startsWith("https://github.com/")) ? data.app.sourceUrl.split("archive")[0] : "")
      }
    }
  })


  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  const handleSaveSecret = async (value) => {
    try {
      // Replace 'owner' and 'repo' with your GitHub username and repository name
      const response = await octokit.actions.createOrUpdateRepoSecret({
        owner: githubCICDtoken.owner,
        repo: githubCICDtoken.repo,
        name: 'NIFE_ACCESS_TOKEN',
        encrypted_value: value, // You may want to encrypt the value before storing it
      });
      enqueueSnackbar("Step 2 Completed!", {
        preventDuplicate: true,
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
    } catch (error) {
      console.error('Error saving secret:', error);
    }
  };

  const handleSubmitCICD = async (event) => {
    event.preventDefault();
    if (githubCICDtoken) {
      const owner = githubCICDtoken.owner
      const repo = githubCICDtoken.repo
      const path = '.github/workflows/main.yml'
      const content = Buffer.from(code).toString('base64')

      try {
        // Fetch the file to get its SHA if it exists
        const { data: existingFile } = await octokit.repos.getContent({
          owner,
          repo,
          path
        });

        // If file exists, update it
        const response = await octokit.repos.createOrUpdateFileContents({
          owner,
          repo,
          path,
          message: '[nife-action] ♾️ Update main.yml',
          content,
          sha: existingFile.sha
        });
        enqueueSnackbar("Step 1 Completed!", {
          preventDuplicate: true,
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        })

      } catch (error) {
        if (error.status === 404) {
          // If file does not exist, create it
          const response = await octokit.repos.createOrUpdateFileContents({
            owner,
            repo,
            path,
            message: '[nife-action] ♾️ Create main.yml',
            content
          });

          enqueueSnackbar("Step 1 Completed!", {
            preventDuplicate: true,
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
          })
        } else {
          setgithubCICDerror(`Error: ${error.message}`);
        }
      }





      if (token1000Value) {
        handleSaveSecret(token1000Value)
      }



    }

  };

  const handleMarker = (e) => {
    console.log(e)
  }
  const [configValue, setconfigValue] = useState("");
  const [FileContent, setFileContent] = useState("");
  const handleConfigValue = (value) => {
    setconfigValue(value);
    fetch(value)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch file content');
        }
        return response.text();
      })
      .then(text => {
        // Set the file content in the component state
        setFileContent(text);
      })
      .catch(error => {
        console.error('Error fetching file content:', error);
      });
  }
  const [isError, setisError] = useState(false);
  const [fetchLocation, { data: data, loading: loading, error: error }] = useLazyQuery(APP_LOCATION_LIST, {

    variables: { name: props.name },
    onCompleted: (data) => {
      if (data) {
        if (backupValue) {
          data && setRegionsList(data.app.backupRegions)
        }
        else {
          data && setRegionsList(data.app.regions)
        }
      }
      setopenAlert(true)
    }, onError: (err) => {

    }
  });

  const { data: RegionsOptions, loading: RegionsLoading, error: RegionsError, refetch: refetchRegions } = useQuery(REGIONS_OPTIONS, {

    variables: { isLatency: (latencyValue ? "1" : "0") },
    onCompleted: () => {
    }
  });

  const handleClose = () => {
    setIsRedirect(false);
  };

  const handleCloseAlert = () => {
    setopenAlert(false);
  };

  const [token1000Value, settoken1000Value] = useState("");

  const handleCopyToken = () => {

    if (token1000Value) {
      navigator.clipboard.writeText(token1000Value);
      enqueueSnackbar(`Copied to clipboard`, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }
    else {
      enqueueSnackbar(`No token `, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }

  }
  const [token1000] = useMutation(TOKEN_1000, {
    onCompleted: (data) => {
      settoken1000Value(data.setUserTokenExpireTime)

    },
    onError: (err) => {
      enqueueSnackbar(`Couldn't fetch token, ${err.message}`, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
    }
  });
  const [configureRegions, { data: configdata, loading: configloading, error: configerror }] = useMutation(DEPLOY, {
    refetchQueries: [APP_LOCATION_LIST, APPS, OVERVIEW_DATA, ORG_GRAPH_DATA, LOCATIONS_GRAPH_DATA],
    onCompleted: () => {
      setIsDeployed(true)
      LogTrigger("Region deployed", "info", window.location.href, "Deploy")
    },
    onError: () => {
      setIsRedirect(false)
      setisError(true)

    }
  });

  const handleError = () => {
    setisError(false)
    {
      !configloading &&
        enqueueSnackbar(`Error ${configerror.message}`, {
          preventDuplicate: true,
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        })
    }
  }
  useEffect(() => {
    fetchLocation();
    document.title = "Deploy"
    setmarkers([])
    setdeployedMarkers([])
    setbackmarkers([])
    token1000()

    setopenAlert(true)
    {
      data && (data.app.regions && data.app.regions.map((e, i) => {
        var mark = -8;
        if (e.name === "Europe, Paris") {
          mark = 15
        }
        if (e.name === "India,Mumbai2") {
          mark = 15
        }
        if (e.name === "India,Mumbai3") {
          mark = 25
        }

        setdeployedMarkers(oldArray => [...oldArray, {
          markerOffset: mark,
          name: e.name,
          c1: "#05a",
          c2: "#fff",
          c3: "skyblue",
          coordinates: [e.longitude, e.latitude]
        }])
      })
      )
    }

    {
      data && (data.app.backupRegions && data.app.backupRegions.map((e, i) => {
        var mark = -8;
        if (e.name == "Europe, Paris") {
          mark = 15
        }
        if (e.name == "India,Mumbai2") {
          mark = 15
        }
        if (e.name == "India,Mumbai3") {
          mark = 25
        }
        setbackmarkers(oldArray => [...oldArray, {
          markerOffset: mark,
          name: e.name,
          c1: "#05a",
          c2: "#fff",
          c3: "skyblue",
          coordinates: [e.longitude, e.latitude]
        }])
      })
      )
    }
    {
      RegionsOptions && RegionsOptions.getAvailabilityCluster.nodes && RegionsOptions.getAvailabilityCluster.nodes.map((e, i) => {
        var mark = -8;
        if (e.name == "Europe, Paris") {
          mark = 15
        }
        if (e.name == "India,Mumbai2") {
          mark = 15
        }
        if (e.name == "India,Mumbai3") {
          mark = 25
        }
        setmarkers(oldArray => [...oldArray, {
          markerOffset: mark,
          name: e.name,
          c1: "#05a",
          c2: "#fff",
          c3: "skyblue",
          coordinates: [e.longitude, e.latitude]
        }])
      })
    }

  }, [data, RegionsOptions, latencyValue]);


  const handleSubmit = (e) => {
    var temp = []
    regionsList.forEach((i) => {
      if (i.code) {
        temp.push(i.code)
      }
      else {
        temp.push(i.regionCode)
      }
    })
    if (backupValue) {
      configureRegions({ variables: { appId: props.name, backupRegions: temp } });
    }
    else {
      configureRegions({ variables: { appId: props.name, allowRegions: temp } });
    }


    setIsRedirect(true)
  }


  const handleChange = (event) => {
    setlatencyValue(!latencyValue)
  };

  const handleBackupChange = (event) => {
    setbackupValue(!backupValue)
    if (!backupValue) {
      setRegionsList([])
      { data && setRegionsList(data.app.backupRegions) }
    }
    else {
      setRegionsList([])
      { data && setRegionsList(data.app.regions) }
    }



  };

  function millisToMinutesAndSeconds(millis) {
    return (millis / 1000).toFixed(0) + " seconds";
  }

  const classes = useStyles();
  if (RegionsLoading) {
    return (
      <div>
        <Skeleton style={{ margin: "10px" }} />
        <Skeleton variant="rectangular" height={100} style={{ margin: "10px" }} />
      </div>
    );
  }

  if (isError) {
    LogTrigger(isError.message, "error", window.location.href, "Scale App")
    setisError(false)
    handleError()
  }
  if (loading) {
    return (
      <div>
        <Skeleton style={{ margin: "10px" }} />
        <Skeleton variant="rectangular" height={100} style={{ margin: "10px" }} />
      </div>
    );
  }
  if (configloading) {
    return (<Backdrop className={classes.backdrop} open={configloading}>
      <Grid container
        direction="column"
        justifyContent="center"
        alignItems="center">
        <Grid item >
          <IconButton>
            <CircularProgress size={18} color="inherit" style={{ color: "#fff" }} />
          </IconButton>

          Deploying...
        </Grid>
        <Grid item>
          <MySvgFile style={{ width: "100%", height: "300px", minWidth: "100px", marginTop: "5px", maxWidth: "800px" }} />
        </Grid>

      </Grid>
    </Backdrop>)

  }

  if (data) {
    LogTrigger("Deployed app location loaded", "info", window.location.href, "Deploy")
  }
  if (error) return `Error! ${error.message}`;
  if (error) {
    LogTrigger(error.message, "error", window.location.href, "Scale App")
  }

  return (
    <div className={classes.root} style={{ margin: "25px" }}>


      {/* !showGithubCICD && !props.isScale */}
      {(!showGithubCICD && (repoUrl)) && <Paper onClick={(e) => setshowGithubCICD(true)} variant='outlined'
        sx={{
          maxWidth: "300px", p: 1, borderRadius: "12px", textAlign: "center",
          boxShadow: "rgba(58, 59, 69, 0.05) 0px 0.05rem 0.55rem 0px"
        }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <img src={isDarkTheme ? '/OikosDark.svg' : (CONFIG_APP_BASE_COMPANY === "nife" ? "/Logo.svg" : "/Zeke.svg")} style={{ objectFit: "fill", width: 56, height: 30 }} />
          <CgBorderStyleDotted style={{ fontSize: "24px", margin: "0px 10px" }} />
          <IoIosInfinite style={{ fontSize: "24px" }} />
          <CgBorderStyleDotted style={{ fontSize: "24px", margin: "0px 10px" }} />
          <AiFillGithub style={{ fontSize: "24px" }} />
        </Grid>
        {<Button sx={{ textTransform: "capitalize", fontWeight: "bold" }} onClick={(e) => setshowGithubCICD(true)}
        >Integrate CI/CD with Github</Button>}
      </Paper>}


      {!props.isScale && !showGithubCICD &&
        <Grid item sm={12} lg={12}>
          <Typography style={{ textAlign: "center", margin: "0px 0px 8px 0px", fontWeight: "bold" }}>Extend Locations</Typography>
          <Typography style={{ textAlign: "center", margin: "0px 0px 8px 0px" }} variant="body1" > Please select regions from below and click on deploy button to add more regions in your app. </Typography>
        </Grid>}

      {/*showGithubCICD && !props.isScale */}
      {(showGithubCICD) ? <Box>

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Button onClick={(e) => setshowGithubCICD(false)} sx={{ color: "#007fff" }} startIcon={<IoMdArrowRoundBack />}>Back to scale</Button>
          {githubCICDtoken && githubCICDtoken.repo && <Button onClick={(e) => handleSubmitCICD(e)}
            variant="contained"
            endIcon={<IoIosArrowForward />}
            sx={{ borderRadius: "0px", textTransform: "capitalize", boxShadow: "rgba(58, 59, 69, 0.15) 0px 0.15rem 1.75rem 0px" }}
            startIcon={<AiFillGithub />}>Commit main.yaml</Button>}



        </Grid>
        <Box sx={{ bgcolor: "#f5f5f5", color: "#111", border: "gray 1px solid", mb: 1, borderRadius: "5px", fontSize: "12px", p: 1 }}>
          {githubCICDerror ? <Box>
            Error: {githubCICDerror}

            Please make sure User PAT of your account must have..
            <ul>
              <li>
                if you are using <b>OAuth app tokens and personal access tokens (classic)</b> need the <b><i>repo scope</i></b> to commit. The <b><i>workflow scope</i></b> is also required in order to modify files in the <b>.github/workflows</b> directory.
              </li>
              <li>
                if you are using <b>Fine-grained access tokens</b> the token must have <b><i>contents:write</i></b> to commit.
              </li>
            </ul>
            Or copy the code from below and paste to your github repository in <b>.github/workflows/main.yaml</b>
          </Box> : <Box>

            The below code will be comited to <b>.github/workflows</b> directory of your repository to activate CI/CD pipeline.
          </Box>}

        </Box>
        <Grid container spacing={2}>

          {<Grid item xs={12} md={6}>
            <Box>
              <Typography variant='h6' sx={{ fontWeight: "bold", mt: 2, mb: 1 }}>{"Step 1: Commit main.yaml"}</Typography>
              <Typography variant='body2'>{"Copy the code main.ymal and commit to .github/workflows/main.yaml to your repository"}</Typography>
              <Button onClick={() => {
                navigator.clipboard.writeText(code);
                enqueueSnackbar("Done!", {
                  preventDuplicate: true,
                  variant: 'success',
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                  },
                })
              }} variant='contained' disableElevation size='small' sx={{ mt: 1, textTransform: "capitalize" }} startIcon={<MdFileCopy />}>Copy code of  main.yaml</Button>
              <br />
              <Typography variant='h6' sx={{ fontWeight: "bold", mt: 2, mb: 1 }}>{"Step 2: Commit nife.toml"}</Typography>
              <Typography sx={{ mb: 1 }} variant='body2'>{"Download the code nife.toml and commit to ROOT folder of your repository"}</Typography>
              <ConfigDownload cicd={true} appName={props.name} setconfigValue={handleConfigValue} githubLink={""} />
              <br />

              <Typography variant='h6' sx={{ fontWeight: "bold", mt: 2, mb: 1 }}>{"Step 3: Set token"}</Typography>
              <Typography variant='body2'>{"Copy the nife access token and save it as secret named 'NIFE_ACCESS_TOKEN' in your repository"}</Typography>
              <Button onClick={() => {
                handleCopyToken();
              }} variant='contained' disableElevation size='small' sx={{ mt: 1, textTransform: "capitalize" }} startIcon={<MdFileCopy />}>Copy token</Button>

            </Box>
          </Grid>}
          <Grid item xs={12} md={6}>
            <Box>
              <Typography sx={{ pb: 0.5 }} variant='body2'>{"</> Contents of main.yaml (step 1)"}</Typography>
            </Box>
            <TextField fullWidth value={code} onChange={(e) => setCode(e.target.value)} multiline />
          </Grid>
        </Grid>


      </Box> : <Box>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {!props.isScale ? "Filter Criteria for location" : <Typography component="h2" variant="h6" gutterBottom style={{ marginTop: "20px" }}>
            Filter Criteria for location *
          </Typography>}

          <FormControlLabel
            control={<CustomSwitch
              checked={backupValue}
              disabled={(data && data.app && data.app.clusterDetials && data.app.clusterDetials.clusterType === "byoh") || (data && data.app && data.app.status === "Suspended")}
              onChange={handleBackupChange}
              color="primary"
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />}
            labelPlacement="end"
            label="Backup Mode" />
        </Grid>
        <Grid container>
          {data && data.app && data.app.clusterDetials && data.app.clusterDetials.clusterType === "byoh" && <Grid item sm={12} lg={12}>
            <p style={{ textAlign: "center", borderRadius: "12px", background: "rgb(227, 242, 253)", padding: "10px", color: "rgb(33, 150, 243)" }}><IconButton><InfoRounded style={{ fill: "rgb(33, 150, 243)" }} /></IconButton>You cannot scale this application, because you are using a byoh location</p>
          </Grid>}
        </Grid>
        <Grid container>
          <Grid item sm={2}>
            <Button
              variant="outlined"
              disabled={(data && data.app && data.app.clusterDetials && data.app.clusterDetials.clusterType === "byoh") || (data && data.app && data.app.status === "Suspended")}
              style={{
                borderRadius: "5px",
                height: "55px",
              }}
              disableElevation
              onClick={() => setshowFilter(!showFilter)}
              className={classes.btncolor}
              startIcon={<FilterListIcon />}
            >
              Filters
            </Button>


            {showFilter &&
              <div>
                <FormControlLabel
                  control={<Switch
                    checked={latencyValue}
                    onChange={handleChange}
                    disabled={(data && data.app && data.app.clusterDetials && data.app.clusterDetials.clusterType === "byoh") || (data && data.app && data.app.status === "Suspended")}
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />}
                  labelPlacement="end"
                  label="Latency (ms)" />
              </div>}
          </Grid>
          <Grid item sm={8}>
            <Autocomplete
              disabled={(data && data.app && data.app.clusterDetials && data.app.clusterDetials.clusterType === "byoh") || (data && data.app && data.app.status === "Suspended")}
              multiple
              options={RegionsOptions.getAvailabilityCluster.nodes ? RegionsOptions.getAvailabilityCluster.nodes : []}
              defaultValue={regionsList}
              value={regionsList ? regionsList : []}
              onChange={(e, newValue) => {
                setRegionsList(newValue);
              }}

              style={{ marginBottom: "10px" }}
              getOptionLabel={option => option.name}
              getOptionDisabled={(option =>

                (regionsList && regionsList.find(o => o.name === option.name) ? true : false)
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={backupValue ? "Select a Backup Region(s)" : "Select a Region(s)"}
                />
              )}
            />
            <p style={{ fontSize: "13px", textAlign: "center" }}><IconButton><CircleIcon style={{ color: "#2dd881", fontSize: "15px" }} /></IconButton>Active <IconButton><CircleIcon style={{ color: "#5d86f9", fontSize: "15px" }} /></IconButton> Available <IconButton><CircleIcon style={{ color: "#111", fontSize: "15px" }} /></IconButton>Backup Regions </p>

          </Grid>
          <Grid item sm={2}>
            <Button variant='contained' disableElevation size="large" color='primary' style={{
              padding: "5px 20px",
              margin: "0px 00px 0px 10px",
              border: "3px solid transparent",
              borderRadius: "5px",
              height: "55px",
              fontWeight: "bold",
              fontSize: "0.9rem",
            }}
              disabled={(data && data.app && data.app.clusterDetials && data.app.clusterDetials.clusterType === "byoh") || (data && data.app && data.app.status === "Suspended") || role === "Viewer"}
              onClick={handleSubmit}> {backupValue ? "Deploy Backup" : "Deploy"}</Button>
          </Grid>
        </Grid>
        <div style={{textAlign:"center",marginBottom:"5px"}}>
        {RegionsOptions && RegionsOptions.getAvailabilityCluster.nodes && RegionsOptions.getAvailabilityCluster.nodes.map((e)=>{
          return <>
            {e.name} <PingChecker regCode={e.regionCode}/>
          </>
        })}
        </div>
     
        <ComposableMap
          projection="geoEquirectangular"
          width={1000}
          height={450}
        >
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map(geo => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill="#EAEAEC"
                  stroke="#ccc"
                />
              ))
            }
          </Geographies>
          {markers.map(({ name, coordinates, markerOffset, c1, c2, c3 }) => (
            <Marker key={name} coordinates={coordinates} onClick={() => handleMarker(name)}>
              <circle r={4} fill="#344affe8" height={100} width={100} />
              <text
                textAnchor="middle"
                y={markerOffset}
                style={{ fontFamily: "system-ui", fill: "#5D5A6D" }}
              >
                {name}
              </text>
            </Marker>
          ))}


          {backmarkers.map(({ name, coordinates, markerOffset, c1, c2, c3 }) => (
            <Marker key={name} coordinates={coordinates} onClick={() => handleMarker(name)}>
              <circle r={5} fill="#111" height={100} width={100} />
              <text
                textAnchor="middle"
                y={markerOffset}
                style={{ fontFamily: "system-ui", fill: "#5D5A6D" }}
              >
                {name}
              </text>
            </Marker>
          ))}

          {deployedMarkers.map(({ name, coordinates, markerOffset, c1, c2, c3 }) => (
            <CustomTooltip title={<PingChecker regCode={"IND"} />}>
              <Marker key={name} coordinates={coordinates} onClick={() => handleMarker(name)}>
                <circle r={5} fill="#2dd881">
                  <animate attributeName="opacity" dur="1s" values="0.5;1;0.9;0.8;0.7" repeatCount="indefinite" begin="0.1" />
                </circle>
                <circle r={10} fill="#00de034d">
                  <animate
                    attributeName="r"
                    dur="2s"
                    values="10;15;10;10"
                    repeatCount="indefinite"
                    begin="0.1"
                  />
                  <animate attributeName="opacity" dur="2s" values="1;0.7;1;1" repeatCount="indefinite" begin="0.1" />
                </circle>
                <text
                  textAnchor="middle"
                  y={markerOffset}
                  style={{ fontFamily: "system-ui", fill: "#111" }}
                >
                  {name}
                </text>
              </Marker>
            </CustomTooltip>
          ))}
        </ComposableMap>
      </Box>}

      <Divider style={{ marginTop: "10px" }} />
      {!props.isScale &&
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',

          }}
          open={openAlert} onClose={handleCloseAlert}>
          <Alert icon={<CheckCircle fontSize="inherit" />} elevation={3} onClose={handleCloseAlert} severity={"success"} style={{ textAlign: "left" }} >
            <AlertTitle>Deployed Successfully in  {millisToMinutesAndSeconds(props.appTime)}!🎉</AlertTitle>
            Link <strong style={{ cursor: "pointer" }} onClick={() => { window.open(props.applink.startsWith("http") ? props.applink : `http://${props.applink}`, '_blank') }}>{props.applink}</strong>
            <p style={{ color: "grey", fontSize: "smaller", marginTop: "10px" }}>
              <IconButton style={{ padding: "0px" }} size="large">
                <HelpOutlineIcon style={{ fontSize: "15px" }} />
              </IconButton>
              App can take 90 seconds to be accessible, <a href='https://docs.nife.io/docs/Guides/Deployment%20Time' target='_blank'>Read More</a></p>

          </Alert>
        </Snackbar>}





      <Dialog
        open={isRedirect}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        {isDeployed == true ? (
          <div>
            <DialogTitle id="alert-dialog-title">
              Deployed Successfully <IconButton size="large"><CheckCircle style={{ color: "green" }} /></IconButton>


            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">

                <Typography variant="body1" style={{ cursor: "pointer" }} onClick={() => { window.open(configdata.configureRegions.hostname + ":" + configdata.configureRegions.config.definition.services[0].external_port.toString(), '_blank') }}>
                  URL:
                  <b style={{ color: "blue" }}> {configdata.configureRegions.hostname + ":" + configdata.configureRegions.config.definition.services[0].external_port.toString()}</b>
                </Typography>



                <Typography variant="body1" style={{ color: "grey" }}>
                  Note:  This might take few minutes to be accessible. <Link>Read More</Link>
                </Typography>


              </DialogContentText>
            </DialogContent>
          </div>
        ) : (
          <div>
            <DialogTitle id="alert-dialog-title">
              Deploying..
            </DialogTitle>
            <DialogContent>
              Please Wait...
            </DialogContent>

          </div>
        )}

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}
