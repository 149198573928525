import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import { Grid, IconButton, ListItemButton } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { BiSolidDollarCircle } from 'react-icons/bi';
import { FaChartPie, FaCode, FaServer, FaStore, FaUsersCog } from "react-icons/fa";
import { IoIosPeople } from "react-icons/io";
import { MdInterests } from 'react-icons/md';
import { RiDatabase2Fill, RiGitBranchFill } from 'react-icons/ri';
import { TiChartLine, TiLocation } from 'react-icons/ti';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { CustomTooltip } from '../Components/Settings/Organization';
import { CONFIG_APP_PRIMARY_DARK_COLOR, CONFIG_APP_PRIMARY_LIGHT_COLOR } from '../configApp';

const urlsList = [
  {
    url: '/teamboard', title: "Home",
    icon: <HomeIcon style={{ fill: "#160044", fontSize: "25px", }} />,
    iconWhite: <HomeIcon style={{ fill: "#fff", fontSize: "25px", }} />,
    iconDark: <HomeIcon style={{ fill: "gray", fontSize: "25px", }} />,
  },
  {
    url: '/organizations', title: "Organizations",
    className: "demo__orgs_tab",
    icon: <IoIosPeople style={{ fill: "#160044", fontSize: "25px" }} />,
    iconWhite: <IoIosPeople style={{ fill: "#fff", fontSize: "25px" }} />,
    iconDark: <IoIosPeople style={{ fill: "gray", fontSize: "25px" }} />,
  },
  {
    url: '/workloads', title: "Workloads",
    className: "demo__workload_tab",
    icon: <RiGitBranchFill style={{ color: "#160044", fontSize: "25px" }} />,
    iconWhite: <RiGitBranchFill style={{ color: "#fff", fontSize: "25px" }} />,
    iconDark: <RiGitBranchFill style={{ color: "gray", fontSize: "25px" }} />,
  },
  {
    url: '/apps', title: "Apps",
    className: "demo__apps_tab",
    icon: <FaServer style={{ fill: "#160044", fontSize: "20px", margin: "3px" }} />,
    iconWhite: <FaServer style={{ fill: "#fff", fontSize: "20px", margin: "3px" }} />,
    iconDark: <FaServer style={{ fill: "gray", fontSize: "20px", margin: "3px" }} />,
  },
  {
    url: '/sites', title: "Sites",
    className:"demo__site",
    icon: <FaCode style={{ fill: "#160044", fontSize: "20px", margin: "3px" }} />,
    iconWhite: <FaCode style={{ fill: "#fff", fontSize: "20px", margin: "3px" }} />,
    iconDark: <FaCode style={{ fill: "gray", fontSize: "20px", margin: "3px" }} />,
  },
  {
    url: '/databases', title: "Databases",
    className:"demo__database",
    icon: <RiDatabase2Fill style={{ fill: "#160044", fontSize: "25px" }} />,
    iconWhite: <RiDatabase2Fill style={{ fill: "#fff", fontSize: "25px" }} />,
    iconDark: <RiDatabase2Fill style={{ fill: "gray", fontSize: "25px" }} />,
  },
  {
    url: '/location', title: "Locations",
    className:"demo_location",
    icon: <TiLocation style={{ fill: "#160044", fontSize: "25px" }} />,
    iconWhite: <TiLocation style={{ fill: "#fff", fontSize: "25px" }} />,
    iconDark: <TiLocation style={{ fill: "gray", fontSize: "25px" }} />,
  },
  {
    url: '/analytics', title: "User Analytics",
    className:"demo_analytics",
    icon: <FaChartPie style={{ fill: "#160044", fontSize: "20px", margin: "3px" }} />,
    iconWhite: <FaChartPie style={{ fill: "#fff", fontSize: "20px", margin: "3px" }} />,
    iconDark: <FaChartPie style={{ fill: "gray", fontSize: "20px", margin: "3px" }} />,
  },
  {
    url: '/Cost-Monitoring', title: "Cost Monitoring",
    className:"demo_cost_monitoring",
    icon: <BiSolidDollarCircle style={{ fill: "#160044", fontSize: "25px", }} />,
    iconWhite: <BiSolidDollarCircle style={{ fill: "#fff", fontSize: "25px", }} />,
    iconDark: <BiSolidDollarCircle style={{ fill: "gray", fontSize: "25px", }} />,
  },
  {
    url: '/tools', title: "Tools",
    icon: <MdInterests  style={{ fill: "#160044", fontSize: "25px" }} />,
    iconWhite: <MdInterests  style={{ fill: "#fff", fontSize: "25px" }} />,
    iconDark: <MdInterests  style={{ fill: "gray", fontSize: "25px" }} />,
  },
  {
    url: '/activity', title: "Activity",
    className:"demo_acivity",
    icon: <TiChartLine style={{ fill: "#160044", fontSize: "25px" }} />,
    iconWhite: <TiChartLine style={{ fill: "#fff", fontSize: "25px" }} />,
    iconDark: <TiChartLine style={{ fill: "gray", fontSize: "25px" }} />,
  },
  {
    url: '/marketplace', title: "Market Place",
    className:"demo_market_place",
    icon: <FaStore style={{ fill: "#160044", fontSize: "20px", margin: "3px" }} />,
    iconWhite: <FaStore style={{ fill: "#fff", fontSize: "20px", margin: "3px" }} />,
    iconDark: <FaStore style={{ fill: "gray", fontSize: "20px", margin: "3px" }} />,
  },
  {
    url: '/members', title: "Members",
    className:"demo_member",
    icon: <FaUsersCog style={{ fill: "#160044", fontSize: "20px", margin: "3px" }} />,
    iconWhite: <FaUsersCog style={{ fill: "#fff", fontSize: "20px", margin: "3px" }} />,
    iconDark: <FaUsersCog style={{ fill: "gray", fontSize: "20px", margin: "3px" }} />,
  },
  {
    url: '/settings/account', title: "Settings",
    className: "demo__settings",
    icon: <SettingsIcon style={{ fill: "#160044", fontSize: "25px", }} />,
    iconWhite: <SettingsIcon style={{ fill: "#fff", fontSize: "25px", }} />,
    iconDark: <SettingsIcon style={{ fill: "gray", fontSize: "25px", }} />,
  },

]
export function MainListItems(props) {
  return (
    <>
      {urlsList.map((e, i) => {
        return (
          <div key={i} className={e.className ? e.className : "undefined"}>
            <Link to={e.url} style={{ textDecoration: "none", color: "#111" }}>
              <ListItemButton style={{ borderRadius: "24px", width: "220px", marginLeft: "10px", marginRight: "10px", marginBottom: "0px" }}>
                <CustomTooltip title={e.title} arrow placement="right-start">
                  <ListItemIcon>
                    {e.icon}
                  </ListItemIcon>
                </CustomTooltip>
                <ListItemText primary={<span style={{ fontWeight: false ? "bold" : "normal" }}>{e.title}</span>} />
              </ListItemButton>
            </Link>
          </div>
        )
      })}
    </>
  )
};

export function MainDarkListItems() {
  return (
    <>
      {urlsList.map((e, i) => {
        return (
          <div key={i} className={e.className ? e.className : "undefined"}>
            <Link to={e.url} style={{ textDecoration: "none", color: "#ededed" }}>
              <ListItemButton style={{ borderRadius: "24px", width: "220px", marginLeft: "10px", marginRight: "10px", marginBottom: "0px" }}>
                <CustomTooltip title={e.title} arrow placement="right-start">
                  <ListItemIcon>
                    {e.iconDark}
                  </ListItemIcon>
                </CustomTooltip>
                <ListItemText primary={<span style={{ fontWeight: false ? "bold" : "normal" }}>{e.title}</span>} />
              </ListItemButton>
            </Link>
          </div>
        )
      })}
    </>
  )
};

export function NewListItems() {
  let history = useHistory()
  const [selectedTab, setselectedTab] = useState(0);
  const isDarkTheme = useTheme().palette.mode === 'dark';

  const handleOnClick = (value, item) => {
    setselectedTab(value)
    history.push(item.url)
  }

  useEffect(() => {
    let value = window.location.href.split("/");

    if (value[value.length - 1] === "location") {
      document.title = "Location";
      setselectedTab(6)
      return null;
    }

    if (value[value.length - 3] === "location") {
      document.title = "Location";
      setselectedTab(6)
      return null;
    }

    if (value[value.length - 1] === "sites") {
      document.title = "Sites";
      setselectedTab(4)
      return null;
    }

    if (value[value.length - 2].startsWith("site")) {
      document.title = "Site";
      setselectedTab(4)
      return null;
    }

    if (value[value.length - 3] === "deployments") {
      document.title = "Apps";
      setselectedTab(3)
      return null;
    }
    if (value[value.length - 1] === "account") {
      document.title = "Settings";
      setselectedTab(13)
      return null;
    }
    if (value[value.length - 1] === "teamboard") {
      document.title = "Home";
      setselectedTab(0)
      return null;
    }
    if (value[value.length - 1].startsWith("orgs?")) {
      document.title = "Organizations";
      setselectedTab(1)
      return null;
    }

    if (value[value.length - 1].startsWith("organizations")) {
      document.title = "Organizations";
      setselectedTab(1)
      return null;
    }

    if (value[value.length - 1].startsWith("apps")) {
      document.title = "Apps";
      setselectedTab(3)
      return null;
    }

    if (value[value.length - 1].startsWith("databases")) {
      document.title = "Databases";
      setselectedTab(5)
      return null;
    }

    if (value[value.length - 1].startsWith("workloads")) {
      document.title = "Workloads";
      setselectedTab(2)
      return null;
    }

    if (value[value.length - 1].startsWith("analytics")) {
      document.title = "User analytics";
      setselectedTab(7)
      return null;
    }

    if (value[value.length - 1].startsWith("Cost-Monitoring")) {
      document.title = "Cost monitoring";
      setselectedTab(8)
      return null;
    }
    if (value[value.length - 1].startsWith("tools")) {
      document.title = "Tools";
      setselectedTab(9)
      return null;
    }
    if (value[value.length - 1].startsWith("activity")) {
      document.title = "Activity";
      setselectedTab(10)
      return null;
    }
    if (value[value.length - 1].startsWith("marketplace")) {
      document.title = "Marketplace";
      setselectedTab(11)
      return null;
    }
    if (value[value.length - 1].startsWith("members")) {
      document.title = "Members";
      setselectedTab(12)
      return null;
    }
    if (value[value.length - 1].startsWith("analytics")) {
      document.title = "User analytics";
      setselectedTab(7)
      return null;
    }

  }, [window.location.href]);

  return (
    <div>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={0}
      >
        {urlsList.map((e, i) => (
          <div key={i} className={e.className ? e.className : "undefined"}>
            <CustomTooltip title={e.title} placement="right-start">
              <Grid item onClick={() => handleOnClick(i, e)}>
                {isDarkTheme ?
                  <IconButton sx={{ mx: 3, borderRadius: "12px", borderBottom: selectedTab === i ? "2px solid #00d7ff" : undefined, background: selectedTab === i ? CONFIG_APP_PRIMARY_DARK_COLOR : "transparent", transition: 'transform 0.2s ease-in-out', '&:hover': { backgroundColor: selectedTab === i ? "#e3f2fd" : '#000', transform: 'scale(1.1)' } }}>
                    {selectedTab === i ? e.icon : e.iconDark}
                  </IconButton>
                  :
                  <IconButton sx={{ mx: 3, borderRadius: "12px", borderBottom: selectedTab === i ? "2px solid #00d7ff" : undefined, background: selectedTab === i ? CONFIG_APP_PRIMARY_LIGHT_COLOR : "transparent", transition: 'transform 0.2s ease-in-out', '&:hover': { backgroundColor: selectedTab === i ? "#16004496" : '#f4f4f4', transform: 'scale(1.1)' } }}>
                    {selectedTab === i ? e.iconWhite : e.icon}
                  </IconButton>}
              </Grid>
            </CustomTooltip>
          </div>
        ))}
      </Grid>
    </div>
  )
}
