import Button from '@mui/material/Button';
import React from 'react';
import GoogleLogin from 'react-google-login';
import { AiOutlineGoogle } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';
import { googleLogin, googleobj } from '../actions/index';
import { CustomTooltip } from './Settings/Organization';
import { CONFIG_APP_GOOGLE_LOGIN_CLIENT } from '../configApp';

function GoogleLoginButton(props) {
  let history = useHistory();
  const handleLoad = (e) => {
 
  setIsload(e)
}
  const [isloggedIn, setIsloggedIn] = React.useState(true)
  const [isload, setIsload] = React.useState(true)

  const handleResponse = (value) => {
    setIsloggedIn(value)
    if (value=="Register"){
       
       history.push("/google-register");
    }
  }
  var CryptoJS = require("crypto-js");
  function encodeAes(plaintText) {
    var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_KEY);
    var encryptedData = CryptoJS.AES.encrypt(plaintText, key, {
      iv: key,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    encryptedData = encryptedData.ciphertext.toString();
    return encryptedData

  }

  const responseGoogle = (response) => {
    if (response.tokenObj ){
      props.googleLogin({"accessToken":encodeAes(response.tokenObj.access_token),"ssoType":"google"},handleResponse)
      props.googleobj(response)
    }
   

   /* return <Redirect to='/google-register'/>*/
}


return (
  <GoogleLogin
    clientId={CONFIG_APP_GOOGLE_LOGIN_CLIENT}
    buttonText="Login"
    onAutoLoadFinished={handleLoad}
    render={renderProps => (
      <CustomTooltip title={isload ? "Loading.." : "Connect With Google"}>
        <Button size='large' disabled={renderProps.disabled} fullWidth variant='outlined' disableElevation onClick={renderProps.onClick} sx={{textTransform:"capitalize",borderRadius:"24px",border:"1px solid rgba(0, 0, 0, 0.12)",py:1}}>{isload ? <AiOutlineGoogle style={{fontSize:"35px"}}/>: <FcGoogle style={{fontSize:"35px",color:"white"}}/>}</Button>
      </CustomTooltip>
    )}
    onSuccess={responseGoogle}
    onFailure={responseGoogle}
    cookiePolicy={'single_host_origin'}
  />
)}

const mapStateToProps =  ( {authReducer} ) => {

    return { authReducer }
}
export default connect(mapStateToProps, { googleLogin,googleobj })(GoogleLoginButton)