import { gql, useMutation, useQuery } from '@apollo/client';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import { APP_LIST } from "../../Home/Apps";
import { APPS } from "../../Home/DeploymentTable";
import { APP_LOCATION_LIST } from "../Deploy/Main";
import { APP, APP_NAME_LIST } from "../Overview/Main";

import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';
import Slide from '@mui/material/Slide';
import Countdown from "react-countdown";
import ReactCountryFlag from "react-country-flag";
import { Redirect, useRouteMatch } from "react-router-dom";
import LogTrigger from '../../../LogTrigger';
import { USER_ACTIVITY } from '../../Settings/Nav';
import { OVERVIEW_DATA } from '../../Home/Dashboard';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { MdLocationPin } from 'react-icons/md';


export const DELETE_APP = gql`
mutation DeleteRegions($appId: ID!, $regionCode: String!){
  deleteApp(appId: $appId, regionCode: $regionCode){
    id
    name
    status
  }
}`

const DELETE_TEXTERNAL_APP = gql`
mutation DeleteExternalRegions($appName: String!){
	deleteDuploApp(appName: $appName){
		message
  }
}`



const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
}));

export default function Main(props) {
	const role = localStorage.getItem("role")
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const permissions = JSON.parse(localStorage.getItem('permissions'))
	const [open, setOpen] = React.useState(false);
	const [opendel, setOpendel] = React.useState(false);
	const [selectedApp, setSelectedApp] = React.useState({});
	const [isChange, setIsChange] = React.useState(false);
	const [isTerminated, setisTerminated] = React.useState(false)
	const classes = useStyles();
	const [appId, setappId] = React.useState("");

	const [deleteApp, { data: delData, loading: delLoad, error: delErr }] = useMutation(DELETE_APP, {
		refetchQueries: [APP_LOCATION_LIST, APPS, APP_LIST, USER_ACTIVITY, APP_NAME_LIST, OVERVIEW_DATA],
		onCompleted: () => {
			enqueueSnackbar("Deleted the app", {
				preventDuplicate: true,
				variant: 'success',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			})
			refetch();
			setOpen(false)
			setOpendel(false)
			setisTerminated(true)
		}
		, onError: (err) => [
			enqueueSnackbar(err.message, {
				preventDuplicate: true,
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			})
		]
	})

	const match = useRouteMatch();
	const handleDelete = () => {
		deleteApp({ variables: { appId: props.appId ? props.appId : match.params.id, regionCode: selectedApp.code } })


	}


	const handleToggle = (value) => () => {

		setIsChange(true)
		setSelectedApp(value)



	};

	const handledeleteapp = () => {
		deleteApp({ variables: { appId: appId, regionCode: "" } })
	}

	const handleCloseDeleteAppDialog = () => {
		if (props.closeDialog) {
			props.closeDialog();
		}

	}
	const handleCancel = () => {
		setIsChange(false)
	}
	const handleClickOpen = (item) => {
		setOpen(true);
	};

	const handleClickOpendel = (item) => {
		setOpendel(true);
	};

	const handleClose = () => {
		setOpen(false);
	}

	const handleClosedel = () => {
		setOpendel(false);
	}
	let history = useHistory();

	// const { loading: load, error: err, data: appdata } = useQuery(APP, { variables: { name: appId } });

	const { loading, error, data, refetch } = useQuery(APP_LOCATION_LIST, {
		variables: { name: props.appId ? props.appId : match.params.id },
		onError: (err) => {
			if (err.message === "sql: Rows are closed") {
				history.push("/")
				if (props.closeDialog) {
					props.closeDialog();
				}
			}
		}
	},);

	const [flagLoaded, setFlagLoaded] = React.useState(true);

    const handleError = () => {
        setFlagLoaded(false);
    };

	useEffect(() => {
		if (props.appId) {
			console.log(props)
			setappId(props.appId)
		}
		else {
			setappId(match.params.id)
		}

	}, [data]);


	if (loading) {
		return (
			<div>
				<Skeleton width="30%" style={{ margin: "10px" }} />
				<Skeleton variant="rectangular" height={100} style={{ margin: "10px" }} />
			</div>
		);
	}


	if (!permissions[0].delete) return `You don't have access to delete this app`
	if (delErr) {
		LogTrigger(delErr.message, "error", window.location.href, "DELETE_APP m")
	}

	if (error) {
		LogTrigger(error.message, "error", window.location.href, "APP_LOCATION_LIST q")
	}

	if (delData) {
		LogTrigger(`Region deleted`, "info", window.location.href, "DELETE_APP")
	}
	if (data) {
		LogTrigger(`App location length - ${data.app.regions && data.app.regions.length}`, "info", window.location.href, "APP_LOCATION_LIST q")
	}
	if (error && error.message === "sql: Rows are closed") {
		<Redirect to='/teamboard' />
	}
	if (error) return `Error!! ${error.message}`;
	if (isTerminated && data && data.app.regions === null) {
		const renderer = ({ hours, minutes, seconds, completed }) => {
			if (completed) {
				return <Redirect to='/teamboard' />
			} else {
				return (
					<Typography variant="body1" gutterBottom style={{ margin: "20px" }}>This app is terminated, redirecting to home page in..{seconds}</Typography>

				);
			}
		};
		return (
			<Countdown date={Date.now() + 5000} renderer={renderer} />);

	}
	return (

		<div style={{ margin: "20px" }}>

			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				fullWidth
				PaperProps={{
					elevation: 0,
					sx: {
						borderRadius: "12px",
						border: "solid 1px gray",
						boxShadow: "#000 5px 5px, #000 10px 10px"

					}
				}}
				onClose={handleClose}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				{delErr ? <div style={{ margin: "10px", height: "100px" }}>Error :{delErr.message},


					<Button style={{ textAlign: "center", marginTop: "0px", borderRadius: "25px" }} onClick={handleClose} color="primary">
						Close
					</Button>
				</div> : <>
					<DialogTitle id="alert-dialog-slide-title">{isChange ? "Are you sure?" : `Regions`}</DialogTitle>

					<DialogContent>

						{isChange ? <div>
							<p style={{ marginBottom: "2px" }}>Do you want to Delete <b style={{ color: "#6C63FF" }}>{appId}</b> from <b style={{ color: "#6C63FF" }}>{selectedApp.name}</b>?</p>
							<DialogContentText id="alert-dialog-slide-description">
								Once you delete your app or regions, there is no going back, Please be certain.
							</DialogContentText>

						</div> : (<List className={classes.root} fullWidth>

							{data.app.regions == null ? ("No regions") : data.app.regions.map((item, key) => (
								<div>
									<ListItem>
										<ListItemIcon>
											{flagLoaded ? (
												<ReactCountryFlag
													countryCode={item.code.slice(0, 2)}
													svg
													style={{
														width: '2em',
														height: '2em',
													}}
													title={item.code}
													onError={handleError}
												/>
											) : (
												<MdLocationPin style={{ width: '2em', height: '2em' }} />
											)}
										</ListItemIcon>
										<ListItemText id="switch-list-label-wifi" primary={item.name}
										/>
										<ListItemSecondaryAction>
											<Button variant="outlined"
												disabled={role === "Viewer"}
												endIcon={<DeleteIcon />}
												onClick={handleToggle(item)}
												style={{ marginLeft: "20px", backgroundColor: "white", color: "#E95A5A", borderSize: "0px", borderColor: "#E95A5A", borderStyle: "none" }}
											>
												Delete
											</Button>
										</ListItemSecondaryAction>

									</ListItem>
								</div>
							))}
						</List>)}
					</DialogContent>

					<DialogActions>
						<div style={{ textAlign: "center", marginBottom: "10px" }}>
							{isChange ? (<div><Button style={{ textAlign: "center", margin: "00px", borderRadius: "25px" }} onClick={handleCancel}>
								No, Cancel
							</Button>
								<Button disabled={delLoad} style={{ textAlign: "center", margin: "0px", borderRadius: "25px", color: "#E95A5A" }} onClick={e => { handleDelete() }} color="primary">
									Yes, Delete it
								</Button></div>) : (<Button style={{ textAlign: "center", marginTop: "0px", borderRadius: "25px" }} onClick={handleClose} color="primary">
									Close
								</Button>)}
						</div>

					</DialogActions>
				</>}
			</Dialog>

			<Dialog
				open={opendel}
				PaperProps={{
					elevation: 0,
					sx: {
						borderRadius: "12px",
						border: "solid 1px gray",
						boxShadow: "#000 5px 5px, #000 10px 10px"

					}
				}}
				onClose={handleClosedel}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Deleting your App is irreversible and your all regions will be deleted too. please be certain.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClosedel} >
						No, Cancel.
					</Button>
					<Button disabled={delLoad} onClick={handledeleteapp} color="primary" autoFocus>
						Yes, Delete it.
					</Button>
				</DialogActions>
			</Dialog>

			{!props.appId ?
				<Grid container>

					<Grid item lg={4}>
						<h2 style={{ color: "#E95A5A" }}>Delete App</h2>
						<Typography variant="body2" gutterBottom style={{ color: "grey" }}>
							Deleting your App and its Regions is irreversible.
						</Typography>
						<a target='_blank' href={"https://nife.trainn.co/share/JM9i58Fj8ByOTirVwOzobA"}>
							Read More
						</a>
					</Grid>
					<Grid item lg={8}>
						{data.app.regions ? <>
							<Typography variant="overline" display="block" gutterBottom>You have {data.app.regions ? data.app.regions.length : 0} Regions</Typography>
							<Button variant="outlined" onClick={(e) => { handleClickOpen() }}> Manage Regions</Button></> :


							<Button variant="outlined" style={{ color: "#e95a5a", marginTop: "10px" }} onClick={(e) => { handleClickOpendel() }}> Delete App</Button>}

					</Grid>
				</Grid>





				: <div>

					{delErr ?
						<div style={{ margin: "10px", height: "100px" }}>Error :{delErr.message} </div> : <>
							<div>
								{isChange ? <div>
									<Typography>Do you want to Delete <b>{appId}</b> from <b>{selectedApp.name}?</b></Typography>
									<Typography style={{ color: "grey", marginBottom: "10px" }}>
										Once you delete your app or regions, there is no going back, Please be certain.
									</Typography>

								</div> : (<List className={classes.root} fullWidth>

									{data.app.regions == null ? (<div>


										<h2 style={{ margin: "0px", paddingBottom: "5px" }}>{"Are you sure?"}</h2>
										<span>

											Deleting your App is irreversible and your all regions will be deleted too. please be certain.

										</span>
										<div>
											<DialogActions>
												<Button fullWidth disableElevation style={{ marginTop: "10px" }} onClick={handleCloseDeleteAppDialog}>
													No, close.
												</Button>
												<Button disabled={delLoad} fullWidth color="error" variant="contained" disableElevation style={{ marginTop: "10px" }} onClick={handledeleteapp}>
													Yes, Delete it.
												</Button>
											</DialogActions>

										</div>
									</div>) : data.app.regions.map((item, key) => (

										<ListItem key={item.id}>
											<ListItemIcon>
												<ReactCountryFlag
													countryCode={item.code.slice(0, 2)}
													svg
													style={{
														width: '2em',
														height: '2em',
													}}
													title={item.code}
												/>
											</ListItemIcon>
											<ListItemText id="switch-list-label-wifi" primary={item.name}
											/>
											<ListItemSecondaryAction>
												<Button variant="outlined"
													endIcon={<DeleteIcon />}
													onClick={handleToggle(item)}
													style={{ marginLeft: "20px", backgroundColor: "white", color: "#E95A5A", borderSize: "0px", borderColor: "#E95A5A", borderStyle: "none" }}
												>
													Delete
												</Button>
											</ListItemSecondaryAction>

										</ListItem>

									))}
								</List>)}
							</div>


							<div style={{ textAlign: "center", marginBottom: "10px" }}>
								{isChange && (
									<DialogActions>
										<Button fullWidth color="primary" onClick={handleCancel}>
											No, Cancel
										</Button>
										<Button disabled={delLoad} variant='contained' disableElevation fullWidth color="error" onClick={e => { handleDelete() }}>
											Yes, Delete it
										</Button>
									</DialogActions>
								)}
							</div>
						</>}
				</div>}



		</div>

	)
}	